import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../subscribe/firebase';

const BottomBanner = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [newsContent, setNewsContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const VISIBLE_NEWS_COUNT = 4;

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const newsCollection = collection(db, 'news');
        const newsQuery = query(newsCollection, orderBy('timestamp', 'desc'));
        const newsSnapshot = await getDocs(newsQuery);
        const allNews = newsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // Filter news for top banner
        const filteredNews = allNews.filter(news => news.position === 'bottom');
        setNewsContent(filteredNews);
      } catch (error) {
        console.error('Failed to fetch news:', error);
      }
    };

    fetchNews();
  }, []);

  const fetchNews = async () => {
    try {
      const newsCollection = collection(db, 'news');
      const newsQuery = query(newsCollection, orderBy('timestamp', 'desc'));
      const newsSnapshot = await getDocs(newsQuery);
      const allNews = newsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      // Filter news for top banner
      const filteredNews = allNews.filter(news => news.position === 'bottom');
      setNewsContent(filteredNews);
    } catch (error) {
      console.error('Failed to fetch news:', error);
    }
  };

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
    setIsVisible(position > 100); // Change 100 to the scroll value at which you want the banner to appear
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const showPreviousImages = () => {
    setCurrentImageIndex(prevIndex =>
      (prevIndex - VISIBLE_NEWS_COUNT + newsContent.length) % newsContent.length
    );
  };

  const showNextImages = () => {
    setCurrentImageIndex(prevIndex =>
      (prevIndex + VISIBLE_NEWS_COUNT) % newsContent.length
    );
  };

  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  if (error) {
    return <div>{error}</div>;
  }

  // Filter out undefined or null news content
  const validNewsContent = newsContent.filter(news => news);

  const displayedNews = [];
  for (let i = 0; i < VISIBLE_NEWS_COUNT; i++) {
    const news = validNewsContent[(currentImageIndex + i) % validNewsContent.length];
    if (news) {
      displayedNews.push(news);
    }
  }

  return (
    <div className={`banner-container ${isVisible ? 'visible' : ''}`}>
      <button className="arrow left" onClick={showPreviousImages}>&lt;</button>
      <div className="image-container">
        {displayedNews.map((news, index) => (
          <div key={news.id} className="banner-item flex border rounded-md mx-3">
            <Link to={`/news/${news.id}`} className='flex'>
              <img src={news.imageUrl || '/placeholder-image.jpg'} alt={`Image ${index + 1}`} className="banner-image h-20 w-8" />
              <h2 className='font-bold'>
                {news.heading.length > 50 ? `${news.heading.slice(0, 50)}...` : news.heading}
              </h2>
            </Link>
          </div>
        ))}
      </div>
      <button className="arrow right" onClick={showNextImages}>&gt;</button>
    </div>
  );
};

export default BottomBanner;
