import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD15xGNzioVbHC0KPSp77jWJITOynbAMZM",
  authDomain: "news-web-575cb.firebaseapp.com",
  projectId: "news-web-575cb",
  storageBucket: "news-web-575cb.appspot.com",
  messagingSenderId: "66477182727",
  appId: "1:66477182727:web:ef73104d25fe53b1203fa2",
  measurementId: "G-S3J5BFNEKS"
};

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const app = initializeApp(firebaseConfig);

// const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

// Initialize Analytics only if available (safe-guard for environments where analytics might not be needed)
if (typeof window !== "undefined") {
  if (getAnalytics) {
    getAnalytics(app);
  }
}


export { db, storage, auth };

// export const db=getFirestore(app);
// export default app;

