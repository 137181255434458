import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const EditPassword = ({user}) => {
    const [email, setEmail] = useState("");
    return (
        <div className="2xl:container loginpage_cntr1 flex flex-col justify-cente items-center mx-auto">
            <div className="md:flex ">
                <div className="col-xs-12 col-sm-12 col-md-6 loginpage_col11 w-full xl:px-20 2xl:px-40 lg:px-4 md:px-3 px-3 pt-10">
                    <div className="container1 loginpage_subcntr1">
                        <div className="md:flex ">
                            {/* <div className="col-xs-4 col-sm-2 col-md-3 loginpage_subcol_back_icon">
                                <a href="/" className="loginpage_subcol_back_icon_link">
                                    <span className="loginpage_subcol_back_icon_content hide">
                                        <i className="fa fa-arflex-left"></i>Back
                                    </span>
                                </a>
                            </div> */}
                            <div className="col-xs-12 col-sm-9 col-md-8 loginpage_subcol_content pt-8 w-full">
                                <div className="md:flex justify-between md:mx-0 mx-6">
                                    <div className="col-xs-12 col-sm-2 col-md-2 loginpage_subcol_logo">
                                        <Link to="/">
                                            <img src={require("../assets/image/logo.png")} alt="Live Law" title="Live Law" className="loginpage_subcol_logo_img w-20" />
                                        </Link>
                                    </div>
                                    {/* <div className="col-xs-12 col-sm-10 col-md-10 loginpage_subcol_progressbar w-[70%]">
                                        <ul>
                                            <li><i className="fa"></i><p>Register</p></li>
                                            <li><i className="fa"></i><p>Payment</p></li>
                                            <li><i className="fa"></i><p>Summary</p></li>
                                        </ul>
                                    </div> */}
                                </div>


                                <div className="login_continue_with w-full">
                                    <form >
                                        <div className='my-6'>
                                            <h2 className='font-bold md:text-3xl text-xl text-[#363636]'>Forgot Password?</h2>
                                            <p className='text-[#363636]'>Please enter your email to receive a password reset link.</p>
                                        </div>

                                        <div className="mb-3 flex flex-col">
                                            <label className='mb-1 text-[#363636] font-bold'>Register Email ID</label>
                                            <input
                                                type="email"
                                                className="form-control p-2 border rounded-md shadow-inner"
                                                placeholder="name@email.com"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </div>

                                        <div className="d-grid">
                                            <Link to={`/forgotpassword?id=${user?.email}`}>
                                                <button className="subscription_buttonlogin font-bold w-full">
                                                    Submit
                                                </button>
                                            </Link>
                                        </div>
                                        {/* <p className="forgot-password text-right">
                                            New user <a href="/register">Register Here</a>
                                        </p> */}
                                        {/* <SignInwithGoogle /> */}
                                    </form>
                                </div>
                                <div className="flex mt-2">
                                    <h6 className="loginpage_subscription_login">Not Subscribe ?
                                        <Link to="/pricing" className="loginpage_text_login">Subscribe now</Link>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" bg-loginbg w-full h-full large-screen-images pt-40">
                    <div className="flex px-16 pt-20 py-4">
                        <div className="col-xs-12 col-sm-12 col-md-12 loginpage_subcntr2_title font-bold text-lg">
                            <h3>India’s no.1 legal news portal</h3>
                        </div>
                    </div>
                    <div className='px-16 py-8 gap-6'>
                        <div className="flex loginpage_subcntr2_subcol_flex py-3">
                            <div className="col-xs-1 col-sm-1 col-md-1 loginpage_subcntr2_subcol">
                                <img src={require("../assets/image/free/login1.jpg")} className="loginpage_subcntr2_subcol_img w-6" />
                            </div>
                            <div className="col-xs-11 col-sm-11 col-md-11 loginpage_subcntr2_subcol2">
                                <p className="loginpage_subcntr2_subcol2_details ml-6">Ad free content</p>
                            </div>
                        </div>
                        <div className="flex loginpage_subcntr2_subcol_flex py-3">
                            <div className="col-xs-1 col-sm-1 col-md-1 loginpage_subcntr2_subcol">
                                <img src={require("../assets/image/free/login1.jpg")} className="loginpage_subcntr2_subcol_img w-6" alt="zip icon" />
                            </div>
                            <div className="col-xs-11 col-sm-11 col-md-11 loginpage_subcntr2_subcol2">
                                <p className="loginpage_subcntr2_subcol2_details ml-6">
                                    Unlimited access to our archives, orders and judgement copies, etc.
                                </p>
                            </div>
                        </div>

                        <div className="flex loginpage_subcntr2_subcol_flex py-3">
                            <div className="col-xs-1 col-sm-1 col-md-1 loginpage_subcntr2_subcol">
                                <img src={require("../assets/image/free/login2.jpg")} className="loginpage_subcntr2_subcol_img w-6" alt="downloads icon" />
                            </div>
                            <div className="col-xs-11 col-sm-11 col-md-11 loginpage_subcntr2_subcol2">
                                <p className="loginpage_subcntr2_subcol2_details ml-6">
                                    Free copies of judgments with download facility
                                </p>
                            </div>
                        </div>

                        <div className="flex loginpage_subcntr2_subcol_flex py-3">
                            <div className="col-xs-1 col-sm-1 col-md-1 loginpage_subcntr2_subcol">
                                <img src={require("../assets/image/free/login3.jpg")} className="loginpage_subcntr2_subcol_img w-6" alt="digest icon" />
                            </div>
                            <div className="col-xs-11 col-sm-11 col-md-11 loginpage_subcntr2_subcol2">
                                <p className="loginpage_subcntr2_subcol2_details ml-6">
                                    Access to weekly and monthly digests
                                </p>
                            </div>
                        </div>

                        <div className="flex loginpage_subcntr2_subcol_flex py-3">
                            <div className="col-xs-1 col-sm-1 col-md-1 loginpage_subcntr2_subcol">
                                <img src={require("../assets/image/free/login4.jpg")} className="loginpage_subcntr2_subcol_img w-6" alt="folder icon" />
                            </div>
                            <div className="col-xs-11 col-sm-11 col-md-11 loginpage_subcntr2_subcol2">
                                <p className="loginpage_subcntr2_subcol2_details ml-6">
                                    Special coverage on Tax, IBC, Arbitration
                                </p>
                            </div>
                        </div>

                        <div className="flex loginpage_subcntr2_subcol_flex py-3">
                            <div className="col-xs-1 col-sm-1 col-md-1 loginpage_subcntr2_subcol">
                                <img src={require("../assets/image/free/login5.jpg")} className="loginpage_subcntr2_subcol_img bellicon_img w-6" alt="bell icon" />
                            </div>
                            <div className="col-xs-11 col-sm-11 col-md-11 loginpage_subcntr2_subcol2">
                                <p className="loginpage_subcntr2_subcol2_details ml-6">
                                    Exclusive notifications on phone and via email. Weekly judgement text/ video roundups
                                </p>
                            </div>
                        </div>

                        <div className="flex loginpage_subcntr2_subcol_flex py-3">
                            <div className="col-xs-1 col-sm-1 col-md-1 loginpage_subcntr2_subcol">
                                <img src={require("../assets/image/free/login6.jpg")} className="loginpage_subcntr2_subcol_img w-6" alt="files icon" />
                            </div>
                            <div className="col-xs-11 col-sm-11 col-md-11 loginpage_subcntr2_subcol2">
                                <p className="loginpage_subcntr2_subcol2_details ml-6">
                                    In-depth articles on current legal and constitutional issues
                                </p>
                            </div>
                        </div>


                    </div>
                    {/* Add other flexs with similar structure */}
                </div>
            </div>
            <div className="">
                {/* <ToastContainer /> */}
            </div>
        </div>
    )
}

export default EditPassword