import React, { useEffect, useState } from 'react';
import { deleteDoc, doc, getDocs, orderBy, query } from 'firebase/firestore';
import { db, storage } from '../subscribe/firebase';
import { toast } from 'react-toastify';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

const Ads = () => {
    const [newsContent, setNewsContent] = useState({
        image: null,
        imageUrl: '',
        pages: []
    });
    const [uploadProgress, setUploadProgress] = useState(0);
    const [loading, setLoading] = useState(true);
    const [ads, setAds] = useState([]);

    const handleUpload = async () => {
        if (!newsContent.imageUrl) {
            toast.error('Please fill in all required fields.');
            return;
        }

        try {
            if (newsContent.image) {
                const storageRef = ref(storage, `images/${newsContent.image.name}`);
                const uploadTask = uploadBytesResumable(storageRef, newsContent.image);

                uploadTask.on(
                    'state_changed',
                    (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        setUploadProgress(progress);
                    },
                    (error) => {
                        toast.error(`Image upload failed: ${error.message}`);
                    },
                    async () => {
                        try {
                            const imageUrl = await getDownloadURL(uploadTask.snapshot.ref);
                            await addDoc(collection(db, 'ads'), {
                                imageUrl,
                                timestamp: serverTimestamp(),
                                pages: newsContent.pages  // Store selected pages with ad
                            });
                            toast.success('News content uploaded successfully!');
                            fetchAds(); // Refresh ads after upload
                        } catch (error) {
                            toast.error(`Failed to get download URL: ${error.message}`);
                        }
                    }
                );
            } else {
                await addDoc(collection(db, 'ads'), {
                    imageUrl: '',
                    timestamp: serverTimestamp(),
                    pages: newsContent.pages  // Store selected pages with ad
                });
                toast.success('News content uploaded successfully!');
                fetchAds(); // Refresh ads after upload
            }
        } catch (error) {
            toast.error(`Failed to upload news: ${error.message}`);
        }
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setNewsContent(prevState => ({
                ...prevState,
                image: file,
                imageUrl: URL.createObjectURL(file)
            }));
        }
    };

    useEffect(() => {
        fetchAds();
    }, []);

    const fetchAds = async () => {
        try {
            const newsCollection = collection(db, 'ads');
            const newsQuery = query(newsCollection, orderBy('timestamp', 'desc'));
            const newsSnapshot = await getDocs(newsQuery);
            const allAds = newsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            // Filter ads based on selected pages
            const filteredAds = allAds.filter(ad => {
                if (ad.pages && ad.pages.length > 0) {
                    return ad.pages.some(page => newsContent.pages.includes(page));
                }
                return true; // If no pages specified, show on all pages
            });

            setAds(filteredAds);
            setLoading(false);
        } catch (error) {
            console.error('Failed to fetch ads:', error);
            setLoading(false);
        }
    };

    const deleteAd = async (id) => {
        try {
            await deleteDoc(doc(db, 'ads', id));
            toast.success('Ad deleted successfully!');
            fetchAds(); // Refresh ads list after deletion
        } catch (error) {
            toast.error(`Failed to delete ad: ${error.message}`);
        }
    };

    const handlePageChange = (e) => {
        const { value, checked } = e.target;
        setNewsContent(prevState => {
            const pages = checked
                ? [...prevState.pages, value]
                : prevState.pages.filter(page => page !== value);
            return { ...prevState, pages };
        });
    };

    return (
        <>
            <div className="form-group">
                <label>Image:</label>
                <input
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={handleImageChange}
                    className="form-control"
                />
                {newsContent.imageUrl && (
                    <img
                        src={newsContent.imageUrl}
                        alt="Uploaded"
                        className="mt-2"
                        style={{ maxWidth: '200px', height: 'auto' }}
                    />
                )}
                <p>Progress: {uploadProgress}%</p>
                <div>
                    <div className='font-bold'>
                        Pages:
                    </div>

                    <div className='md:flex w-full'>
                        <div>
                            <label>
                                <input type="checkbox" value="home" checked={newsContent.pages.includes('home')} onChange={handlePageChange} />
                                Custom
                            </label>
                            <label>
                                <input type="checkbox" value="gst" checked={newsContent.pages.includes('gst')} onChange={handlePageChange} />
                                GST
                            </label>
                            <label>
                                <input type="checkbox" value="enforcement-directorate" checked={newsContent.pages.includes('enforcement-directorate')} onChange={handlePageChange} />
                                Enforcement Directorate (ED)
                            </label>
                            <label>
                                <input type="checkbox" value="dri" checked={newsContent.pages.includes('dri')} onChange={handlePageChange} />
                                DRI
                            </label>
                            <label>
                                <input type="checkbox" value="dggi" checked={newsContent.pages.includes('dggi')} onChange={handlePageChange} />
                                DGGI
                            </label>
                            <label>
                                <input type="checkbox" value="income-tax-department" checked={newsContent.pages.includes('income-tax-department')} onChange={handlePageChange} />
                                Income Tax Department
                            </label>
                            <label>
                                <input type="checkbox" value="cbi" checked={newsContent.pages.includes('cbi')} onChange={handlePageChange} />
                                CBI
                            </label>
                            <label>
                                <input type="checkbox" value="narcotics-control-bureau" checked={newsContent.pages.includes('narcotics-control-bureau')} onChange={handlePageChange} />
                                Narcotics Control Bureau
                            </label>
                            <label>
                                <input type="checkbox" value="gold-smuggling" checked={newsContent.pages.includes('gold-smuggling')} onChange={handlePageChange} />
                                Gold Smuggling
                            </label>
                            <label>
                                <input type="checkbox" value="transfer-posting" checked={newsContent.pages.includes('transfer-posting')} onChange={handlePageChange} />
                                Transfer-Posting
                            </label>
                            <label>
                                <input type="checkbox" value="politics" checked={newsContent.pages.includes('politics')} onChange={handlePageChange} />
                                Politics
                            </label>
                            <label>
                                <input type="checkbox" value="legal-advice" checked={newsContent.pages.includes('legal-advice')} onChange={handlePageChange} />
                                Legal Advice
                            </label>
                            <label>
                                <input type="checkbox" value="interviews" checked={newsContent.pages.includes('interviews')} onChange={handlePageChange} />
                                Interviews
                            </label>
                            <label>
                                <input type="checkbox" value="editorial" checked={newsContent.pages.includes('editorial')} onChange={handlePageChange} />
                                Editorial
                            </label>
                            {/* Add more pages as needed */}
                        </div>
                    </div>
                    <button type="button" onClick={handleUpload} className="upload-button bg-footerColor text-white px-4 py-2 mt-4">
                        Upload
                    </button>
                </div>
                <div className='grid grid-cols-3 gap-3 py-12'>
                    {loading ? (
                        <div>Loading...</div>
                    ) : (
                        ads.map((ad) => (
                            <div key={ad.id} className='border p-3 shadow-md rounded-md'>
                                <img src={ad.imageUrl} alt="Ad" className='w-full h-48 object-cover py-3' />
                                <p className='text-xs pb-3'>Page: {ad.pages}</p>
                                <button onClick={() => deleteAd(ad.id)} className='bg-red-500 text-white p-2 rounded-md'>Delete</button>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </>
    )
}
export default Ads;
