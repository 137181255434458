import React, { useEffect } from 'react';

const MetaTags = ({ title, description, image, url }) => {
    useEffect(() => {
        const setMetaTag = (property, content) => {
            let element = document.querySelector(`meta[property="${property}"]`);
            if (!element) {
                element = document.createElement('meta');
                element.setAttribute('property', property);
                document.head.appendChild(element);
            }
            element.setAttribute('content', content);
        };

        setMetaTag('og:title', title);
        setMetaTag('og:description', description);
        setMetaTag('og:image', image);
        setMetaTag('og:url', url);
        setMetaTag('og:type', 'article');
    }, [title, description, image, url]);

    return null;
};

export default MetaTags;
