import React, { useEffect, useState } from 'react'
import { deleteDoc, doc, getDocs } from 'firebase/firestore';
import { db, storage } from '../subscribe/firebase'; // Adjust the path as needed
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

const BannerNews = () => {
    const [users, setUsers] = useState([]);
    const [news, setNews] = useState([]);
    const [newsList, setNewsList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeSection, setActiveSection] = useState('dashboard');
    const [newsContent, setNewsContent] = useState({
        heading: '',
        link: '',
        image: null,
        imageUrl: '',
        // view_paragraph: '',
        // type: 'simple',
        // position: 'top'
    });
    const [uploadProgress, setUploadProgress] = useState(0);
    const [user, setUser] = useState(null);
    const [isPremium, setIsPremium] = useState(false);
    const [selectedPage, setSelectedPage] = useState('');

    const handleUpload = async () => {
        if (!newsContent.heading) {
            toast.error('Please fill in all required fields.');
            return;
        }

        try {
            if (newsContent.image) {
                const storageRef = ref(storage, `images/${newsContent.image.name}`);
                const uploadTask = uploadBytesResumable(storageRef, newsContent.image);

                uploadTask.on(
                    'state_changed',
                    (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        setUploadProgress(progress);
                    },
                    (error) => {
                        toast.error(`Image upload failed: ${error.message}`);
                    },
                    async () => {
                        try {
                            const imageUrl = await getDownloadURL(uploadTask.snapshot.ref);
                            await addDoc(collection(db, 'banner-news'), {
                                heading: newsContent.heading,
                                link: newsContent.link,
                                imageUrl,
                                // view_paragraph: newsContent.view_paragraph,
                                // // pages: newsContent.pages,
                                // position: newsContent.position,
                                // type: newsContent.type,  // Validate this field
                                // timestamp: serverTimestamp()
                            });
                            resetNewsContent();
                            toast.success('News content uploaded successfully!');
                            fetchNews();
                        } catch (error) {
                            toast.error(`Failed to get download URL: ${error.message}`);
                        }
                    }
                );
            } else {
                await addDoc(collection(db, 'banner-news'), {
                    heading: newsContent.heading,
                    link: newsContent.link,
                    imageUrl: '',
                    // view_paragraph: newsContent.view_paragraph,
                    // pages: newsContent.pages,
                    // type: newsContent.type,  // Validate this field
                    // position: newsContent.position,  // Validate this field
                    // timestamp: serverTimestamp()
                });
                resetNewsContent();
                toast.success('News content uploaded successfully!');
                fetchNews();
            }
        } catch (error) {
            toast.error(`Failed to upload news: ${error.message}`);
        }
    };

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const newsCollection = collection(db, 'banner-news');
                const newsSnapshot = await getDocs(newsCollection);
                const newsList = newsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setNewsList(newsList);
                setLoading(false);
            } catch (error) {
                toast.error(`Failed to fetch news: ${error.message}`);
                setLoading(false);
            }
        };


        fetchNews();
    }, []);

    const fetchNews = async () => {
        try {
            const newsCollection = collection(db, 'banner-news');
            const newsSnapshot = await getDocs(newsCollection);
            const newsList = newsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setNewsList(newsList);
            setLoading(false);
        } catch (error) {
            toast.error(`Failed to fetch news: ${error.message}`);
            setLoading(false);
        }
    };

    const handlePageSelect = (page) => {
        setSelectedPage(page);
    };

    const deleteNews = async (id) => {
        try {
            await deleteDoc(doc(db, 'banner-news', id));
            toast.success('News deleted successfully!');
            fetchNews(); // Refresh news list after deletion
        } catch (error) {
            toast.error(`Failed to delete news: ${error.message}`);
        }
    };

    const resetNewsContent = () => {
        setNewsContent({
            heading: '',
            link: '',
            imageUrl: '',
            // view_paragraph: '',
            // image: null,
            // position: 'top',
            // type: 'simple'
        });
        setUploadProgress(0);
    };



    const handleNewsChange = (e) => {
        const { name, value } = e.target;
        setNewsContent(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setNewsContent(prevState => ({
                ...prevState,
                image: file,
                imageUrl: URL.createObjectURL(file)
            }));
        }
    };

    const handlePageChange = (e) => {
        const { value, checked } = e.target;
        setNewsContent(prevState => {
            const pages = checked
                ? [...prevState.pages, value]
                : prevState.pages.filter(page => page !== value);
            return { ...prevState, pages };
        });
    };


    return (
        <div>
            <div className="admin-container rounded-md">
                <h1 className='bg-footerColor p-3 text-white font-bold my-3 rounded-md'>User Details</h1>
                {/* {loading ? (
                    <p>Loading...</p>
                ) : ( */}
                <div>
                    <form>
                        <div className="form-group">
                            <label>Heading:</label>
                            <input
                                type="text"
                                name="heading"
                                value={newsContent.heading}
                                onChange={handleNewsChange}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group">
                            <label>Link:</label>
                            <input
                                type="link"
                                name="link"
                                value={newsContent.link}
                                onChange={handleNewsChange}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group">
                            <label>Image:</label>
                            <input
                                type="file"
                                name="image"
                                accept="image/*"
                                onChange={handleImageChange}
                                className="form-control"
                            />
                            {newsContent.imageUrl && (
                                <img
                                    src={newsContent.imageUrl}
                                    alt="Uploaded"
                                    className="mt-2"
                                    style={{ maxWidth: '200px', height: 'auto' }}
                                />
                            )}
                            <p>Progress: {uploadProgress}%</p>
                        </div>
                        {/* <div className="form-group">
                            <label>Paragraph:</label>
                            <textarea
                                name="paragraph"
                                value={newsContent.paragraph}
                                onChange={handleNewsChange}
                                className="form-control"
                                rows={10}
                            ></textarea>
                        </div>
                        <div className="form-group">
                            <label>View Paragraph:</label>
                            <textarea
                                name="view_paragraph"
                                value={newsContent.view_paragraph}
                                onChange={handleNewsChange}
                                className="form-control"
                            ></textarea>
                        </div>
                        <div className="form-group">
                            <label>Image:</label>
                            <input
                                type="file"
                                name="image"
                                accept="image/*"
                                onChange={handleImageChange}
                                className="form-control"
                            />
                            {newsContent.imageUrl && (
                                <img
                                    src={newsContent.imageUrl}
                                    alt="Uploaded"
                                    className="mt-2"
                                    style={{ maxWidth: '200px', height: 'auto' }}
                                />
                            )}
                            <p>Progress: {uploadProgress}%</p>
                        </div>

                        <div className="form-group">
                            <label>Banner Position:</label>
                            <select
                                name="position"
                                value={newsContent.position}
                                onChange={handleNewsChange}
                                className="form-control"
                            >
                                <option value="top">Top Banner</option>
                                <option value="bottom">Bottom Banner</option>
                            </select>
                        </div>

                        <div className='flex items-center gap-3'>
                            <label>News Type:</label>
                            <select name="type" value={newsContent.type} onChange={handleNewsChange}>
                                <option value="simple">Simple</option>
                                <option value="premium">Premium</option>
                            </select>
                        </div> */}
                        <button type="button" onClick={handleUpload} className="upload-button bg-footerColor text-white px-4 py-2 mt-4">
                            Upload
                        </button>
                    </form>
                    <div className='grid grid-cols-3 gap-3 py-12'>

                        {newsList.map((news) => (
                            <div key={news.id} className='border p-3 shadow-md rounded-md'>
                                <h2 className='font-bold'>{news.heading}</h2>
                                <img src={news.imageUrl} alt="News" className='w-full h-48 object-cover py-3' />
                                <p className='text-sm'>{news.link}</p>
                                {/* <p className='text-xs py-3'>{news.view_paragraph}</p>
                                <p className='text-xs pb-3'>Type: {news.type}</p> */}
                                <button onClick={() => deleteNews(news.id)} className='bg-red-500 text-white p-2 rounded-md'>Delete</button>
                            </div>
                        ))}

                    </div>
                </div>
                {/* // )} */}
            </div>
        </div>
    )
}

export default BannerNews