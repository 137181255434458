import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import SimpleNews, { PopularPost } from '../Array';  // Ensure this path is correct
import Header from '../views/Header';
import Footer from '../views/Footer';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { db } from '../subscribe/firebase'; // Adjust the import path as needed
import { toast } from 'react-toastify';


const BannerNewsDetails = () => {
    const { id } = useParams();  // Retrieve the 'id' parameter from the URL
    const [news, setNews] = useState(null);
    const [loading, setLoading] = useState(true);
    const [newsList, setNewsList] = useState([]);

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const newsCollection = collection(db, 'popular-news');
                const newsSnapshot = await getDocs(newsCollection);
                const newsList = newsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setNewsList(newsList);
                setLoading(false);
            } catch (error) {
                toast.error(`Failed to fetch news: ${error.message}`);
                setLoading(false);
            }
        };


        fetchNews();
    }, []);

    const fetchNews = async () => {
        try {
            const newsCollection = collection(db, 'popular-news');
            const newsSnapshot = await getDocs(newsCollection);
            const newsList = newsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setNewsList(newsList);
            setLoading(false);
        } catch (error) {
            toast.error(`Failed to fetch news: ${error.message}`);
            setLoading(false);
        }
    };

    useEffect(() => {
        const fetchNewsDetail = async () => {
            try {
                const docRef = doc(db, 'news', id);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    setNews(docSnap.data());
                } else {
                    toast.error('No such news article!');
                }
            } catch (error) {
                toast.error(`Failed to fetch news detail: ${error.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchNewsDetail();
    }, [id]);

    if (loading) {
        return <div className='w-full h-full flex justify-center items-center'>
            <img src={require('../assets/image/logo.png')} alt="" className='w-[10%] flex justify-center mt-64 items-center' />
        </div>;
    }

    if (!news) {
        return <div className='w-full h-full flex justify-center items-center'>
            <img src={require('../assets/image/logo.png')} alt="" className='w-[10%] flex justify-center mt-64 items-center' />
        </div>;
    }


    const timestampToDate = (timestamp) => {
        if (timestamp && timestamp.seconds) {
            return new Date(timestamp.seconds * 1000); // Convert to milliseconds
        }
        return null;
    };

    // Format the date for display
    const formatDate = (date) => {
        return new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        }).format(date);
    };

    const newsDate = timestampToDate(news.timestamp);
    const formattedDate = newsDate ? formatDate(newsDate) : 'Date not available';



    return (
        <div>
            <Header />

            <div className='xl:px-40 lg:px-20 md:px-10 px-3 py-3 container w-full md:flex mx-auto'>
                <div className='md:border-r-2 md:p-6 p-3 md:w-[960px]'>
                    <div className=''>
                        <h2 className='py-3 text-red-600 font-bold md:text-lg'>Revenue News Network</h2>
                        <h1 className='font-extrabold text-xl'>{news.heading}</h1>
                        <h1 className=''>{formattedDate}</h1>
                        {news.imageUrl && <img src={news.imageUrl} alt="News" className='py-6' />}
                        {/* <p>{news.paragraph}</p> */}
                        {/* <p>{news.view_paragraph}</p> */}
                    </div>

                    <div className='text-center md:p-16'>
                        <h2 className='md:text-3xl text-xl font-bold mb-3'>This is a Premium Content Available exclusively to our subscriber</h2>
                        <Link to='/pricing' className='my-3'>
                            <button className='border p-2 my-3 text-white subscription_buttonlogin font-bold'>
                                Subscribe
                            </button>
                        </Link>
                        <img src={require('../assets/image/logo.png')} alt="" className='mx-auto w-32' />
                        <h2 className='py-3 text-red-600 font-bold md:text-3xl text-xl'>
                            INR 1099<span className='text-lg'>+GST</span>
                        </h2>
                        <p className='text-lg'>Your support helps us to bring you  more content at <br />  an affordable  subscription scheme !!! <br /> <Link to='/pricing' className='text-red-600 cursor-pointer'>All payment options available</Link></p>
                    </div>
                </div>
                {/* <div className='md:border-r-2 md:p-6 p-3 md:w-[960px]'>
                    <h1 className='font-extrabold'>{newsItem.title}</h1>
                    <h2 className='pt-3 text-red-600 font-bold md:text-lg'>Revenue News Network</h2>
                    <h2 className='text-gray-600'>{newsItem.date}</h2>
                    <img src={newsItem.image} alt={newsItem.title} className='w-full md:py-6 py-3' />
                    <p>{newsItem.para}</p>
                </div> */}


                <div className='md:p-6 p-3'>
                    <img src={require("../assets/image/Ad1.png")} alt="" className='w-full' />
                    <div className='py-3'>
                        <h2 className='text-xl font-bold'>Popular Post</h2>
                    </div>
                    {newsList.map((news, index) => (
                        <div className='flex my-3' key={index}>
                            <Link to={`/popular-news/${news.id}`} className='flex'>
                                <img src={news.imageUrl} alt="" className='rounded-md w-[140px] h-[100px]' />
                                <div className='mx-3'>
                                    <h2 className='font-extrabold'>{news.heading.length > 50 ? `${news.heading.slice(0, 50)}...` : news.heading}</h2>
                                    <p className=' '>{news.paragraph.length > 50 ? `${news.paragraph.slice(0, 50)}...` : news.paragraph}</p>
                                    {/* <p className='text-gray-600 text-sm'>{news.view_paragraph}</p> */}
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>

            </div>
            <Footer />
        </div>
    );
};

export default BannerNewsDetails;
