import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { collection, doc, getDoc, getDocs, orderBy, query } from 'firebase/firestore';
import { db } from '../subscribe/firebase';
import { toast } from 'react-toastify';
import { FaFacebook, FaLinkedin, FaTwitter, FaWhatsapp } from 'react-icons/fa';
import Header from '../views/Header';
import Footer from '../views/Footer';
import MetaTags from './MetaTags';

const NewsDetails = () => {
    const { id } = useParams();
    const [news, setNews] = useState(null);
    const [loading, setLoading] = useState(true);
    const [newsContent, setNewsContent] = useState([]);
    const [newsList, setNewsList] = useState([]);

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const newsCollection = collection(db, 'popular-news');
                const newsSnapshot = await getDocs(newsCollection);
                const newsList = newsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setNewsList(newsList);
                setLoading(false);
            } catch (error) {
                toast.error(`Failed to fetch news: ${error.message}`);
                setLoading(false);
            }
        };
        fetchNews();
    }, []);

    useEffect(() => {
        const fetchPremiumNews = async () => {
            try {
                const newsCollection = collection(db, 'news');
                const newsQuery = query(newsCollection, orderBy('timestamp', 'desc'));
                const newsSnapshot = await getDocs(newsQuery);
                const allNews = newsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                const filteredNews = allNews.filter(news => news.type === 'premium');
                setNewsContent(filteredNews);
            } catch (error) {
                console.error('Failed to fetch news:', error);
            }
        };
        fetchPremiumNews();
    }, []);

    useEffect(() => {
        const fetchNewsDetail = async () => {
            try {
                const docRef = doc(db, 'news', id);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    setNews(docSnap.data());
                } else {
                    toast.error('No such news article!');
                }
            } catch (error) {
                toast.error(`Failed to fetch news detail: ${error.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchNewsDetail();
    }, [id]);

    useEffect(() => {
        const fetchNewsDetail = async () => {
            try {
                const docRef = doc(db, 'popular-news', id);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    setNews(docSnap.data());
                } else {
                    toast.error('No such news article!');
                }
            } catch (error) {
                toast.error(`Failed to fetch news detail: ${error.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchNewsDetail();
    }, [id]);

    if (loading) {
        return <div className='w-full h-full flex justify-center items-center'>
            <img src={require('../assets/image/logo.png')} alt="" className='w-[10%] flex justify-center mt-64 items-center' />
        </div>;
    }

    if (!news) {
        return <div className='w-full h-full flex justify-center items-center'>
            <img src={require('../assets/image/logo.png')} alt="" className='w-[10%] flex justify-center mt-64 items-center' />
        </div>;
    }

    const timestampToDate = (timestamp) => {
        if (timestamp && timestamp.seconds) {
            return new Date(timestamp.seconds * 1000); // Convert to milliseconds
        }
        return null;
    };

    const formatDate = (date) => {
        return new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        }).format(date);
    };

    const newsDate = timestampToDate(news.timestamp);
    const formattedDate = newsDate ? formatDate(newsDate) : 'Date not available';

    const renderContent = (content) => {
        return <div dangerouslySetInnerHTML={{ __html: content }} />;
    };

    const generateShareUrl = (platform, url, heading) => {
        const encodedUrl = encodeURIComponent(url);
        const encodedHeading = encodeURIComponent(heading);
        switch (platform) {
            case 'facebook':
                return `https://www.facebook.com/share.php?u=${encodedUrl}`;
            case 'twitter':
                return `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedHeading}`;
            case 'whatsapp':
                return `https://wa.me/?text=${encodedHeading}%20${encodedUrl}`;
            case 'linkedin':
                return `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`;
            default:
                return '';
        }
    };


    const currentUrl = window.location.href;
    const shareHeading = news.heading;

    return (
        <div>
            <MetaTags
                title={news.heading}
                description={news.paragraph}
                image={news.imageUrl}
                url={currentUrl}
            />

            <Header />

            <div className='xl:px-40 lg:px-20 md:px-10 px-3 py-3 container w-full lg:flex mx-auto'>
                <div className='md:border-r-2 md:p-6 p-3 md:w-[960px]'>
                    <div>
                        <h2 className='py-3 text-red-600 font-bold md:text-lg'>Revenue News Network</h2>
                        <div className='flex my-3 mb-6 gap-6'>
                            <a href={generateShareUrl('facebook', currentUrl, shareHeading)} target="_blank" rel="noopener noreferrer">
                                <FaFacebook size={30} className='text-blue-600' />
                            </a>
                            <a href={generateShareUrl('twitter', currentUrl, shareHeading)} target="_blank" rel="noopener noreferrer">
                                <FaTwitter size={30} className='text-blue-600' />
                            </a>
                            <a href={generateShareUrl('whatsapp', currentUrl, shareHeading)} target="_blank" rel="noopener noreferrer">
                                <FaWhatsapp size={30} className='text-green-600' />
                            </a>
                            <a href={generateShareUrl('linkedin', currentUrl, shareHeading)} target="_blank" rel="noopener noreferrer">
                                <FaLinkedin size={30} className='text-blue-600' />
                            </a>
                        </div>
                        <h1 className='font-bold text-2xl'>{renderContent(news.heading)}</h1>
                        <h1 className=''>{formattedDate}</h1>
                        {news.imageUrl && <img src={news.imageUrl} alt="News" className='py-6 w-full' />}
                        <p className='pb-6 text-xl'>{renderContent(news.paragraph)}</p>
                        <p>{renderContent(news.view_paragraph)}</p>
                    </div>
                    <div className='md:flex justify-cente items-cente gap-3 my-6 py-6 border-t border-b'>
                        <h2>Tags</h2>
                        <div className='p-2 grid md:grid-cols-5 grid-cols-3 text-center'>
                            {news.tags && news.tags.map((tag, index) => (
                                <Link
                                    key={index}
                                    to={`/${tag}`}
                                    className="p-2 gap-3 bg-gray-200 mx-2 mb-3 rounded-sm "
                                >
                                    {tag}
                                </Link>
                            ))}
                        </div>
                    </div>
                    <div className='w-full'>
                        {newsContent.slice(0, 6).map(news => (
                            <Link to={`/premium-news-detail/${news.id}`} key={news.id} className="flex gap-6 my-6 w-[100%]">
                                <div className="py-2 w-[30%]">
                                    {news.imageUrl && <img src={news.imageUrl} alt="News" className="py-2 md:h-auto h-20 md:w-[300px] w-full" />}
                                </div>
                                <div className="py-2 w-[70%]">
                                    <h2 className='py-3 text-red-600 font-bold md:text-lg'>Revenue News Network</h2>
                                    <h1 className='font-bold'>{renderContent(news.heading)}</h1>
                                    {/* <p className='text-xl'>{renderContent(news.paragraph)}</p> */}
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
                <div className='md:p-6 p-3'>
                    <img src={require("../assets/image/Ad1.png")} alt="" className='w-full' />
                    <div className='py-3'>
                        <h2 className='text-xl font-bold'>Popular Post</h2>
                    </div>
                    {newsList.map((news, index) => (
                        <div className='flex my-3' key={index}>
                            <Link to={`/popular-news/${news.id}`} className='flex'>
                                <img src={news.imageUrl} alt="" className='rounded-md w-[140px] h-[100px]' />
                                <div className='mx-3'>
                                    <h2 className='font-extrabold'>{news.heading.length > 50 ? `${news.heading.slice(0, 50)}...` : news.heading}</h2>
                                    <p className=' '>{news.paragraph.length > 50 ? `${news.paragraph.slice(0, 50)}...` : news.paragraph}</p>
                                    {/* <p className='text-gray-600 text-sm'>{news.view_paragraph}</p> */}
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default NewsDetails;
