import React, { useContext, useEffect, useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import Custom from './Custom';
import SimpleNews, { bannerPost, tax_news } from '../Array';
import { Link } from 'react-router-dom';
import BottomBanner from './BottomBanner';
import { auth, db } from '../subscribe/firebase';
import { getDocs, collection, query, orderBy, where, getDoc, doc } from 'firebase/firestore';
import { AppContext } from './AppContext';
import TopBanner from './TopBanner';
import CustomHome from './CustomHome';
import DGJIHome from './DGJIHome';
import DRIHome from './DRIHome';
import GoldHome from './GoldHome';
import PoliticsHome from './PoliticsHome';
import HealthHome from './HealthHome';
import CBIHome from './CBIHome';
import { toast } from 'react-toastify';
import Enforcement from './Enforcement';
import IncomeTax from './IncomeTax';
import Legal from './Legal';
import Transfer from './Transfer';
import Editorial from './Editorial';
import Inteview from './Inteview';
import BreakingNews from './BreakingNews';
import EditorialHome from './EditorialHome';
import EnforcementHome from './EnforcementHome';
import IncomeTaxHome from './IncomeTaxHome';
import LegalHome from './LegalHome';
import NaroticsHome from './NaroticsHome';
import TransferHome from './TransferHome';
import InterviewHome from './InterviewHome';
import GSTHome from './GSTHome';



const Home = ({ userId }) => {
  // const [user, setUser] = useState();
  // const [news, setNews] = useState([]);
  // useEffect(() => {
  //   auth.onAuthStateChanged((user) => {
  //     setUser(user);
  //   });
  // });
  // useEffect(() => {
  //   const unsubscribe = auth.onAuthStateChanged((user) => {
  //     setUser(user); // Set user state based on Firebase auth state
  //   });

  //   return () => unsubscribe(); // Cleanup on unmount
  // }, []);
  // const handleLogout = () => {
  //   auth.signOut().then(() => {
  //     setUser(null);
  //   }).catch(error => {
  //     console.error("Logout Error: ", error);
  //   });
  // };

  // const [newsContent, setNewsContent] = useState([]);
  // const [imageContent, setImageContent] = useState([]);
  // const [ads, setAds] = useState([]);

  // useEffect(() => {
  //   const fetchNews = async () => {
  //     try {
  //       const newsCollection = collection(db, 'news');
  //       const newsQuery = query(newsCollection, orderBy('timestamp', 'desc'));
  //       const newsSnapshot = await getDocs(newsQuery);
  //       const allNews = newsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  //       // Filter news for home page
  //       const filteredNews = allNews.filter(news => Array.isArray(news.pages) && news.pages.includes('home'));

  //       setNewsContent(filteredNews);
  //       // setLoading(false);
  //     } catch (error) {
  //       console.error('Failed to fetch news:', error);
  //       // setLoading(false);
  //     }
  //   };

  //   fetchNews();
  // }, []);

  // useEffect(() => {
  //   const fetchBanner = async () => {
  //     try {
  //       const newsCollection = collection(db, 'banner');
  //       const newsQuery = query(newsCollection, orderBy('timestamp', 'desc'));
  //       const newsSnapshot = await getDocs(newsQuery);
  //       const allNews = newsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  //       // Filter news for home page
  //       // const filteredNews = allNews.filter(news => Array.isArray(news.pages) && news.pages.includes('home'));

  //       setImageContent(allNews);
  //       // setLoading(false);
  //     } catch (error) {
  //       console.error('Failed to fetch news:', error);
  //       // setLoading(false);
  //     }
  //   };

  //   fetchBanner();
  // }, []);

  // useEffect(() => {
  //   const fetchAds = async () => {
  //     try {
  //       const newsCollection = collection(db, 'ads');
  //       const newsQuery = query(newsCollection, orderBy('timestamp', 'desc'));
  //       const newsSnapshot = await getDocs(newsQuery);
  //       const allNews = newsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  //       // Filter news for home page
  //       const filteredNews = allNews.filter(news => Array.isArray(news.pages) && news.pages.includes('home'));

  //       setAds(filteredNews);
  //       // setLoading(false);
  //     } catch (error) {
  //       console.error('Failed to fetch news:', error);
  //       // setLoading(false);
  //     }
  //   };

  //   fetchAds();
  // }, []);

  const [user, setUser] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const [newsContent, setNewsContent] = useState([]);
  const [imageContent, setImageContent] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isPremium, setIsPremium] = useState();
  const [ads, setAds] = useState([]);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowPopup(true)
    }, 10000)
  }, [])

  const handleClose = () => {
    setShowPopup(false);
  };


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        // console.log('User logged in:', user);
        setUser(user);
        await checkSubscriptionStatus(user.uid);
      } else {
        // console.log('No user logged in');
        setUser([]);
        setIsPremium(false);
        fetchNews(false);
      }
    });

    return () => unsubscribe();
  }, []);


  const checkSubscriptionStatus = async (userId) => {
    if (!userId) {
      setIsPremium(false);
      return; // Exit early if userId is not valid
    }
    try {
      const userDoc = await getDoc(doc(db, 'Users', userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        console.log('User data:', userData);
        const isUserPremium = userData;
        console.log('Is user premium?', isUserPremium);
        setIsPremium(isUserPremium);
        await fetchNews(isUserPremium);
      } else {
        console.log('User document does not exist');
        setIsPremium(false);
        await fetchNews(false);
      }
    } catch (error) {
      console.error('Error fetching subscription status:', error);
      setIsPremium(false);
      await fetchNews(false);
    }
  };

  const fetchNews = async (isPremiumUser) => {
    setLoading(true);
    try {
      const newsQuery = query(collection(db, 'news'), orderBy('timestamp', 'desc'));
      const querySnapshot = await getDocs(newsQuery);
      const fetchedNews = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      const filteredNews = isPremiumUser
        ? fetchedNews
        : fetchedNews.filter(news => news.type === 'simple');

      console.log('Fetched news:', filteredNews);
      setNewsContent(filteredNews);
      setLoading(false);
    } catch (error) {
      toast.error(`Failed to fetch news: ${error.message}`);
      setLoading(false);
    }
  };

  const fetchBanner = async () => {
    try {
      const bannerCollection = collection(db, 'banner');
      const bannerQuery = query(bannerCollection, orderBy('timestamp', 'desc'));
      const bannerSnapshot = await getDocs(bannerQuery);
      const allBanners = bannerSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setImageContent(allBanners);
    } catch (error) {
      console.error('Failed to fetch banner:', error);
    }
  };

  const fetchAds = async () => {
    try {
      const adsCollection = collection(db, 'ads');
      const adsQuery = query(adsCollection, orderBy('timestamp', 'desc'));
      const adsSnapshot = await getDocs(adsQuery);
      const allAds = adsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      const filteredAds = allAds.filter(ad => Array.isArray(ad.pages) && ad.pages.includes('home'));
      setAds(filteredAds);
    } catch (error) {
      console.error('Failed to fetch ads:', error);
    }
  };

  useEffect(() => {
    fetchNews();
    fetchBanner();
    fetchAds();
  }, []);

  const handleLogout = () => {
    auth.signOut().then(() => {
      setUser(null);
    }).catch(error => {
      console.error("Logout Error: ", error);
    });
  };

  const renderContent = (content) => {
    return <div dangerouslySetInnerHTML={{ __html: content }} />;
  };



  return (
    <div>
      {showPopup && <BreakingNews onClose={handleClose} />}
      <Header user={user} onLogout={handleLogout} />
      <div>
        <div className='w-[100%] mx-auto relative md:z-30 -z-50'>
          {imageContent.slice(0, 1).map((banner, index) => (
            <div className='w-full bg-cover '>
              <img src={banner.imageUrl} alt="" className='w-[100%] md:h-[460px] object-cover bg-cover' />
            </div>
          ))}
          <div className="absolute inset-0 bg-black opacity-40"></div>
          {imageContent.slice(0, 1).map((banner, index) => (
            <h2 className='absolute top-4 md:top-16 xl:px-20 2xl:px-40 lg:px-10 md:px-4 px-3 py-6 xl:text-5xl font-extrabold text-white lg:w-[980px]'>{banner.heading}</h2>
          ))}
          <div className='md:z-[999] cursor-pointer'>
            <TopBanner />
          </div>

        </div>
      </div>


      <div className='xl:px-10 2xl:px-40 lg:px-4 md:px-3 px-3 py-6 container w-auto md:w-full mx-auto'>
        <div className='md:flex w-full gap-3'>
          <div className='md:w-[35%] w-full'>
            {newsContent.slice(0, 1).map((news, index) => (
              <Link to={`news/${news.id}`} key={news.id}>
                <img src={news.imageUrl} alt="" className='rounded-md w-full' />
                <h2 className='py-3 font-extrabold'>{renderContent(news.paragraph.length > 280 ? `${news.paragraph.slice(0, 280)}...` : news.paragraph)}</h2>
                <p>{renderContent(news.view_paragraph.length > 280 ? `${news.view_paragraph.slice(0, 280)}...` : news.view_paragraph)}</p>
              </Link>
            ))}
          </div>

          {/* <div className='lg:flex w-full'> */}
          <div className='md:mx-3 md:w-[35%] md:py-0 py-3'>
            {newsContent.slice(1, 5).map((news, index) => (
              <div className='flex mb-3' key={index}>
                <Link to={`news/${news.id}`} className='flex'>
                  <div className='w-[40%]'>
                    <img src={news.imageUrl} alt="" className='rounded-md md:w-[150px] w-[150px] md:h-[90px] border' />
                  </div>
                  <div className='mx-3 md:w-[60%] w-full'>
                    <h2 className='font-extrabold'>
                      {news.heading.length > 25 ? `${news.heading.slice(0, 25)}...` : news.heading}
                    </h2>
                    <p className='text-gray-400 text-sm'>
                      {news.view_paragraph.length > 80 ? `${news.view_paragraph.slice(0, 80)}...` : news.view_paragraph}
                    </p>
                    {/* <p className='text-gray-400 text-sm'>{news.view_paragraph}</p> */}
                  </div>
                </Link>
              </div>
            ))}
          </div>

          <div className='md:w-[35%]'>
            {newsContent.slice(5, 6).map((news, index) => (
              <div className='flex mb-3' key={index}>
                <Link to={`news/${news.id}`} className='flex'>
                  <img src={news.imageUrl} alt="" className='rounded-md md:w-[150px] w-[150px] md:h-[90px] border' />
                  <div className='mx-3 md:w-[60%] w-full'>
                    <h2 className='font-extrabold'>
                      {news.heading.length > 25 ? `${news.heading.slice(0, 25)}...` : news.heading}
                    </h2>
                    <p className='text-gray-400 text-sm'>
                      {renderContent(news.view_paragraph.length > 80 ? `${news.view_paragraph.slice(0, 80)}...` : news.view_paragraph)}
                    </p>
                  </div>
                </Link>
              </div>
            ))}
            <div>
              {ads.slice(0, 1).map((ad, index) => (
                <img src={ad.imageUrl} alt="" className='w-full' />
              ))}
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>

      <div className='xl:px-10 2xl:px-40 lg:px-4 md:px-3 px-3 container mx-auto'>
        <div className='p-3 border-b-4 border-black my-3'>
          <h2 className='text-xl font-bold'>कस्टम</h2>
        </div>
        <div className='py-3'>
          <div className='md:flex gap-6'>
            {newsContent.slice(6, 9).map((news, index) => (
              <div className='gap-6 w-full border-b py-3' key={index}>
                <Link to={`news/${news.id}`} className='gap-3'>
                  <img src={news.imageUrl} alt="" className='rounded-md w-[100%] md:h-[250px]' />
                  <h2 className='font-extrabold'>
                    {news.heading.length > 50 ? `${news.heading.slice(0, 50)}...` : news.heading}
                  </h2>
                  <p className='text-gray-400 text-sm'>
                    {renderContent(news.view_paragraph.length > 80 ? `${news.view_paragraph.slice(0, 80)}...` : news.view_paragraph)}
                  </p>
                </Link>
              </div>
            ))}
          </div>

          <div className='md:flex gap-6 py-6'>
            {newsContent.slice(9, 12).map((news, index) => (
              <div className='gap-6 w-full py-3' key={index}>
                <Link to={`news/${news.id}`} className='flex gap-3'>
                  <img src={news.imageUrl} alt="" className='rounded-md w-[150px] h-[100px]' />
                  <h2 className='font-extrabold'>
                    {news.heading.length > 50 ? `${news.heading.slice(0, 50)}...` : news.heading}
                  </h2>
                  {/* <p className='text-gray-400 text-sm'>
                      {renderContent(news.view_paragraph.length > 80 ? `${news.view_paragraph.slice(0, 80)}...` : news.view_paragraph)}
                    </p> */}
                  {/* <h2 className='md:w-[100%] w-auto'>{news.view_paragraph}</h2> */}
                </Link>
              </div>
            ))}
          </div>

          <div className='md:flex gap-6 py-6'>
            {newsContent.slice(12, 15).map((news, index) => (
              <div className='gap-6 w-full py-3' key={index}>
                <Link to={`news/${news.id}`} className='flex gap-3'>
                  <img src={news.imageUrl} alt="" className='rounded-md w-[150px] h-[100px]' />
                  <h2 className='font-extrabold'>
                    {news.heading.length > 50 ? `${news.heading.slice(0, 50)}...` : news.heading}
                  </h2>
                  {/* <p className='text-gray-400 text-sm'>
                      {renderContent(news.view_paragraph.length > 80 ? `${news.view_paragraph.slice(0, 80)}...` : news.view_paragraph)}
                    </p> */}
                  {/* <h2 className='md:w-[100%] w-auto'>{news.view_paragraph}</h2> */}
                </Link>
              </div>
            ))}
          </div>

          <div>
            {ads.slice(1, 2).map((ad, index) => (
              <img src={ad.imageUrl} alt="" className='w-full' />
            ))}
          </div>

          <div className='md:flex gap-6'>
            {newsContent.slice(15, 18).map((news, index) => (
              <div className='gap-6 w-full border-b py-3' key={index}>
                <Link to={`news/${news.id}`} className='gap-3'>
                  <img src={news.imageUrl} alt="" className='rounded-md w-[100%]' />
                  <h2 className='font-extrabold'>
                    {news.heading.length > 25 ? `${news.heading.slice(0, 25)}...` : news.heading}
                  </h2>
                  <p className='text-gray-400 text-sm'>
                    {renderContent(news.view_paragraph.length > 80 ? `${news.view_paragraph.slice(0, 80)}...` : news.view_paragraph)}
                  </p>
                </Link>
              </div>
            ))}
          </div>

          <div className='md:flex gap-6 py-6'>
            {newsContent.slice(18, 21).map((news, index) => (
              <div className='gap-6 w-full py-3' key={index}>
                <Link to={`news/${news.id}`} className='w-[100%] flex gap-3'>
                  <div className='w-[50%] h-[100px]'>
                    <img src={news.imageUrl} alt="" className='rounded-md ' />
                  </div>
                  <h2 className='font-extrabold w-[50%]'>
                    {news.heading.length > 50 ? `${news.heading.slice(0, 50)}...` : news.heading}
                  </h2>
                  {/* <p className='text-gray-400 text-sm'>
                      {renderContent(news.view_paragraph.length > 80 ? `${news.view_paragraph.slice(0, 80)}...` : news.view_paragraph)}
                    </p> */}
                </Link>
              </div>
            ))}
          </div>

          <div className='md:flex gap-6 py-6'>
            {newsContent.slice(21, 24).map((news, index) => (
              <div className='gap-6 w-full py-3' key={index}>
                <Link to={`news/${news.id}`} className='w-[100%] flex gap-3'>
                  <div className='w-[50%] h-[100px]'>
                    <img src={news.imageUrl} alt="" className='rounded-md ' />
                  </div>
                  <h2 className='font-extrabold w-[50%]'>
                    {news.heading.length > 50 ? `${news.heading.slice(0, 50)}...` : news.heading}
                  </h2>
                  {/* <p className='text-gray-400 text-sm'>
                      {renderContent(news.view_paragraph.length > 80 ? `${news.view_paragraph.slice(0, 80)}...` : news.view_paragraph)}
                    </p> */}
                </Link>
              </div>
            ))}
          </div>


          <div className='grid md:grid-cols-3 grid-cols-1 gap-6 py-3'>
            {newsContent.slice(24, 100).map((news, index) => (
              <div className='gap-6 w-full py-3' key={index}>
                <Link to={`news/${news.id}`} className='w-[100%] flex gap-3'>
                  <div className='w-[50%] h-[100px]'>
                    <img src={news.imageUrl} alt="" className='rounded-md ' />
                  </div>
                  <h2 className='font-extrabold w-[50%]'>
                    {news.heading.length > 50 ? `${news.heading.slice(0, 50)}...` : news.heading}
                  </h2>
                  {/* <p className='text-gray-400 text-sm'>
                      {renderContent(news.view_paragraph.length > 80 ? `${news.view_paragraph.slice(0, 80)}...` : news.view_paragraph)}
                    </p> */}
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div>
        <GSTHome />
      </div>

      <div>
        <EnforcementHome />
      </div>

      <div>
        <DRIHome />
      </div>

      <div>
        <DGJIHome />
      </div>

      <div>
        <IncomeTaxHome />
      </div>

      <div>
        <CBIHome />
      </div>

      <div>
        <NaroticsHome />
      </div>

      <div>
        <GoldHome />
      </div>

      <div>
        <TransferHome />
      </div>

      <div>
        <PoliticsHome />
      </div>

      <div>
        <LegalHome />
      </div>

      <div>
        <InterviewHome />
      </div>

      <div>
        <EditorialHome />
      </div>

      {/* <div>
        <CustomHome />
      </div>
      <div>
        <HealthHome />
      </div> */}
      <Footer />
      <div className='large-screen-images'>
        <BottomBanner />
      </div>
    </div>
  )
}

export default Home