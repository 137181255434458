import React, { useState, useEffect } from 'react';
import { deleteDoc, doc, getDocs } from 'firebase/firestore';
import { db, storage } from '../subscribe/firebase'; // Adjust the path as needed
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'

const Menu = ({ onSelectPage }) => {
    const [selectedPage, setSelectedPage] = useState('');
    const [news, setNews] = useState([]);
	const [newsList, setNewsList] = useState([]);
	const [loading, setLoading] = useState(true);
	const [activeSection, setActiveSection] = useState('dashboard');
	// const [selectedPage, setSelectedPage] = useState('');
    const fetchNews = async () => {
		try {
			const newsCollection = collection(db, 'news');
			const newsSnapshot = await getDocs(newsCollection);
			const newsList = newsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
			setNewsList(newsList);
			setLoading(false);
		} catch (error) {
			toast.error(`Failed to fetch news: ${error.message}`);
			setLoading(false);
		}
	};

	// Filter news based on the selected page
	const filteredNews = newsList.filter(news => 
		selectedPage ? news.pages.includes(selectedPage) : true
	);

	useEffect(() => {
		if (activeSection === 'news') {
			fetchNews();
		}
	}, [activeSection]);

    const handlePageChange = (event) => {
        const page = event.target.value;
        setSelectedPage(page);
        if (onSelectPage) {
            onSelectPage(page); // Ensure this line is correctly updating the state in the parent component
        }
    };

    return (
        <div className="menu-container flex">
            <select value={selectedPage} onChange={handlePageChange} className="page-select rounded-3xl px-2 border">
                <option value=""></option>
                <option value="home">Home</option>
                <option value="custom">Custom</option>
                <option value="dri">DRI</option>
                <option value="dgji">DGJI</option>
                <option value="politics">Politics</option>
                <option value="cbi">CBI</option>
                <option value="gold-smuggling">Gold Smuggling</option>
                <option value="health">Health</option>
                {/* Add more pages as needed */}
            </select>
        </div>
    );
};

export default Menu;
