import React, { useState } from 'react';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../subscribe/firebase";
import { ToastContainer, toast } from "react-toastify";
import SignInwithGoogle from "../subscribe/SignInwithGoogle";
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';

const AdminForm = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [key, setKey] = useState(""); // New state for key

    const correctKey = "5678910"; // The correct key
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();


        // Check if the entered key is correct
        if (key !== correctKey) {
            toast.error("The key is incorrect", {
                position: "bottom-center",
            });
            return; // Exit the function if the key is incorrect
        }

        try {
            await signInWithEmailAndPassword(auth, email, password);
            console.log("User logged in Successfully");
            // window.location.href = "/revenue-news-channel-adminpanel"; 
            toast.success("User logged in Successfully", {
                position: "top-center",
            });
            navigate("/revenue-news-channel-adminpanel");
        } catch (error) {
            console.log(error.message);

            toast.error(error.message, {
                position: "bottom-center",
            });
        }
    };

    return (
        <div className="2xl:container loginpage_cntr1 flex flex-col justify-center items-center mx-auto">
            <div className="md:flex">
                <div className="col-xs-12 col-sm-12 col-md-6 loginpage_col11 w-full xl:px-20 2xl:px-40 lg:px-4 md:px-3 px-3 pt-10">
                    <div className="container1 loginpage_subcntr1">
                        <div className="md:flex ">
                            <div className="col-xs-12 col-sm-9 col-md-8 loginpage_subcol_content pt-8 w-full">
                                <div className="md:flex justify-between">
                                    <div className="col-xs-12 col-sm-2 col-md-2 loginpage_subcol_logo">
                                        <a href="/">
                                            <img src={require("../assets/image/logo.png")} alt="Live Law" title="Live Law" className="loginpage_subcol_logo_img w-20" />
                                        </a>
                                    </div>
                                    <div className="col-xs-12 col-sm-10 col-md-10 loginpage_subcol_progressbar w-[70%]">
                                        <ul>
                                            <li><i className="fa"></i><p>Register</p></li>
                                            <li><i className="fa"></i><p>Payment</p></li>
                                            <li><i className="fa"></i><p>Summary</p></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="">
                                    <div className='flex justify-between'>
                                        <h3 className="loginpage_subcol_login font-bold">Existing Subscriber - Log in</h3>
                                        <Link to="/login">
                                            <button className='bg-gray-900 rounded-3xl px-3 p-1 text-sm text-white'>
                                                User
                                            </button>
                                        </Link>
                                    </div>
                                    <p className="loginpage_subcol_login_content">Enter your credentials to access your account</p>
                                </div>
                                <div className="login_continue_with w-full">
                                    <form onSubmit={handleSubmit}>
                                        {/* <div className='gap-3 mt-3 mb-6'>
                                            <SignInwithGoogle />
                                        </div> */}
                                        <div className="mb-3 flex flex-col">
                                            <label className='mb-1'>Email address</label>
                                            <input
                                                type="email"
                                                className="form-control p-2 border rounded-md shadow-inner"
                                                placeholder="Enter email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </div>
                                        <div className="mb-3 flex flex-col">
                                            <label className='mb-1'>Password</label>
                                            <input
                                                type="password"
                                                className="form-control p-2 border rounded-md shadow-inner"
                                                placeholder="Enter password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                        </div>
                                        <div className="mb-3 flex flex-col">
                                            <label className='mb-1'>Enter Admin Key</label> {/* New input for key */}
                                            <input
                                                type="text"
                                                className="form-control p-2 border rounded-md shadow-inner"
                                                placeholder="Enter key"
                                                value={key}
                                                onChange={(e) => setKey(e.target.value)}
                                            />
                                        </div>
                                        {/* <Link> */}
                                            <div className="d-grid">
                                                <button type="submit" className="subscription_buttonlogin font-bold w-full">
                                                    Submit
                                                </button>
                                            </div>
                                        {/* </Link> */}
                                        {/* <p className="forgot-password text-right">
                                            New user <a href="/register">Register Here</a>
                                        </p> */}
                                    </form>
                                </div>
                                <div className="flex mt-2">
                                    <h6 className="loginpage_subscription_login">Not Registered ? <a href="/register" className="loginpage_text_login">Register now</a></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" bg-loginbg w-full h-full large-screen-images md:pt-40 ">
                    <div className="flex px-16 pt-20 py-4">
                        <div className="col-xs-12 col-sm-12 col-md-12 loginpage_subcntr2_title font-bold text-lg">
                            <h3>India’s no.1 legal news portal</h3>
                        </div>
                    </div>
                    <div className='px-16 py-8 gap-6'>
                        <div className="flex loginpage_subcntr2_subcol_flex py-3">
                            <div className="col-xs-1 col-sm-1 col-md-1 loginpage_subcntr2_subcol">
                                <img src={require("../assets/image/free/login1.jpg")} className="loginpage_subcntr2_subcol_img w-6" />
                            </div>
                            <div className="col-xs-11 col-sm-11 col-md-11 loginpage_subcntr2_subcol2">
                                <p className="loginpage_subcntr2_subcol2_details ml-6">Ad free content</p>
                            </div>
                        </div>
                        <div className="flex loginpage_subcntr2_subcol_flex py-3">
                            <div className="col-xs-1 col-sm-1 col-md-1 loginpage_subcntr2_subcol">
                                <img src={require("../assets/image/free/login1.jpg")} className="loginpage_subcntr2_subcol_img w-6" alt="zip icon" />
                            </div>
                            <div className="col-xs-11 col-sm-11 col-md-11 loginpage_subcntr2_subcol2">
                                <p className="loginpage_subcntr2_subcol2_details ml-6">
                                    Unlimited access to our archives, orders and judgement copies, etc.
                                </p>
                            </div>
                        </div>
                        <div className="flex loginpage_subcntr2_subcol_flex py-3">
                            <div className="col-xs-1 col-sm-1 col-md-1 loginpage_subcntr2_subcol">
                                <img src={require("../assets/image/free/login2.jpg")} className="loginpage_subcntr2_subcol_img w-6" alt="downloads icon" />
                            </div>
                            <div className="col-xs-11 col-sm-11 col-md-11 loginpage_subcntr2_subcol2">
                                <p className="loginpage_subcntr2_subcol2_details ml-6">
                                    Free copies of judgments with download facility
                                </p>
                            </div>
                        </div>
                        <div className="flex loginpage_subcntr2_subcol_flex py-3">
                            <div className="col-xs-1 col-sm-1 col-md-1 loginpage_subcntr2_subcol">
                                <img src={require("../assets/image/free/login3.jpg")} className="loginpage_subcntr2_subcol_img w-6" alt="digest icon" />
                            </div>
                            <div className="col-xs-11 col-sm-11 col-md-11 loginpage_subcntr2_subcol2">
                                <p className="loginpage_subcntr2_subcol2_details ml-6">
                                    Access to weekly and monthly digests
                                </p>
                            </div>
                        </div>
                        <div className="flex loginpage_subcntr2_subcol_flex py-3">
                            <div className="col-xs-1 col-sm-1 col-md-1 loginpage_subcol">
                                <img src={require("../assets/image/free/login4.jpg")} className="loginpage_subcol_img w-6" alt="folder icon" />
                            </div>
                            <div className="col-xs-11 col-sm-11 col-md-11 loginpage_subcol2">
                                <p className="loginpage_subcol2_details ml-6">
                                    Special coverage on Tax, IBC, Arbitration
                                </p>
                            </div>
                        </div>
                        <div className="flex loginpage_subcol_flex py-3">
                            <div className="col-xs-1 col-sm-1 col-md-1 loginpage_subcol">
                                <img src={require("../assets/image/free/login5.jpg")} className="loginpage_subcol_img bellicon_img w-6" alt="bell icon" />
                            </div>
                            <div className="col-xs-11 col-sm-11 col-md-11 loginpage_subcol2">
                                <p className="loginpage_subcol2_details ml-6">
                                    Exclusive notifications on phone and via email. Weekly judgement text/ video roundups
                                </p>
                            </div>
                        </div>
                        <div className="flex loginpage_subcol_flex py-3">
                            <div className="col-xs-1 col-sm-1 col-md-1 loginpage_subcol">
                                <img src={require("../assets/image/free/login6.jpg")} className="loginpage_subcol_img w-6" alt="files icon" />
                            </div>
                            <div className="col-xs-11 col-sm-11 col-md-11 loginpage_subcol2">
                                <p className="loginpage_subcol2_details ml-6">
                                    In-depth articles on current legal and constitutional issues
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="">
                <ToastContainer />
            </div>
        </div>
    );
};

export default AdminForm;
