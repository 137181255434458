import React from 'react';
import Header from '../views/Header';
import Footer from '../views/Footer';
import { FaFacebook, FaTwitter, FaVoicemail, FaWhatsapp, FaYoutube } from 'react-icons/fa';
import { MdCall, MdOutlineMailOutline } from 'react-icons/md';
import { FaLocationDot } from 'react-icons/fa6';

const Contact = () => {
    return (
        <>
            <Header />
            <div className='xl:px-10 2xl:px-40 lg:px-4 md:px-3 px-3 py-6 container w-auto md:w-full mx-auto'>
                <div className="w-full p-3">
                    <div className="md:flex flex-wra w-full">
                        {/* Reach us on Section */}
                        <div className="w-full md:w-1/2 p-12 bg-[#8B8B8D] text-white rounded-xl">
                            <h2 className="text-2xl font-bold mb-6">Reach us on</h2>

                            <div className="flex items-start mb-4 gap-6">
                                <MdOutlineMailOutline size={25} />
                                {/* <img src="images/contact_mail_icon.svg" alt="Email Icon" className="w-8 h-8 mr-3" /> */}
                                <div className=''>
                                    <h6 className="font-bold text-lg">Email</h6>
                                    <p className='py-6'>info@revenue.in</p>
                                    <p>subscription@revenue.in</p>
                                </div>
                            </div>

                            <div className="flex items-start mb-4 gap-6">
                                <MdCall size={25} />
                                {/* <img src="images/contact_call_icon.svg" alt="Phone Icon" className="w-8 h-8 mr-3" /> */}
                                <div className=''>
                                    <h6 className="font-bold text-lg">Number</h6>
                                    <p className='py-6'>+91 7018931870</p>
                                    <p>+91 7994869913</p>
                                </div>
                            </div>

                            <div className="flex items-start mb-4 gap-6">
                                <FaLocationDot size={30} />
                                {/* <img src="images/contact_location_icon.svg" alt="Location Icon" className="w-8 h-8 mr-3" /> */}
                                <div className=''>
                                    <h6 className="font-bold text-lg">Address</h6>
                                    <p className='py-6'>Third Floor, Bhageeratha Residency, Banerjee road Ernakulam, North Cochin, Kerala, India</p>
                                </div>
                            </div>

                            <h4 className="text-xl font-bold mb-4">Follow us on</h4>
                            <div className="flex space-x-4 gap-6">
                                <a href="https://www.facebook.com/livelawindia/" target="_blank" rel="noopener noreferrer">
                                    <FaFacebook size={30} />
                                    {/* <img src="images/contact_facebook_icon.svg" alt="Facebook Icon" className="w-8 h-8" /> */}
                                </a>
                                <a href="https://twitter.com/LiveLawIndia" target="_blank" rel="noopener noreferrer">
                                    <FaTwitter size={30} />
                                    {/* <img src="images/contact_twitter_icon.svg" alt="Twitter Icon" className="w-8 h-8" /> */}
                                </a>
                                <a href="https://api.whatsapp.com/send?phone=+91 8431625405&amp;text=I want to Subscribe for Revenue News Updates on WhatsApp" target="_blank" rel="noopener noreferrer">
                                    <FaWhatsapp size={30} />
                                    {/* <img src="images/contact_whatapp_icon.svg" alt="WhatsApp Icon" className="w-8 h-8" /> */}
                                </a>
                                <a href="https://www.youtube.com/livelawindia" target="_blank" rel="noopener noreferrer">
                                    <FaYoutube size={30} />
                                    {/* <img src="images/contact_youtube_icon.svg" alt="YouTube Icon" className="w-8 h-8" /> */}
                                </a>
                            </div>
                        </div>

                        {/* Contact Us Form Section */}
                        <div className="col-xs-12 col-sm-7 col-md-7 p-4 shadow-md rounded-xl w-full">
                            <div className="col-md-1"></div>
                            <div className="col-md-11 px-16">
                                <h2 className="text-3xl font-bold mb-4">Contact Us</h2>
                                <section className="py-5">
                                    <div className="container mx-auto">
                                        <form name="getInTouchForm" id="getInTouchForm" className="space-y-4">
                                            <input type="hidden" name="form_name" id="form_name" value="contactus" />
                                            <label htmlFor="name" className="block font-medium text-lg mb-1">Full Name</label>
                                            <div className="form-group mb-3">
                                                <input type="text" id="name" name="name" placeholder="Enter Full Name" className="form-control w-full h-12 border p-2" />
                                            </div>
                                            <label htmlFor="email" className="block font-medium text-lg mb-1">Email Address</label>
                                            <div className="form-group mb-3">
                                                <input type="text" id="email" name="email" placeholder="Enter Email Address" className="form-control w-full h-12 border p-2" />
                                            </div>
                                            <label htmlFor="mobile" className="block font-medium text-lg mb-1">Phone Number</label>
                                            <div className="form-group mb-3">
                                                <input type="text" id="mobile" name="mobile" placeholder="Enter Phone Number" className="form-control w-full h-12 border p-2" />
                                            </div>
                                            <label htmlFor="message" className="block font-medium text-lg mb-1">Message</label>
                                            <div className="form-group mb-3">
                                                <input type="text" id="message" name="message" placeholder="Write something..." className="form-control w-full h-12 border p-2" />
                                            </div>
                                            <button type="button" className="btn w-full h-12 bg-red-600 text-white rounded shadow-md">SUBMIT</button>
                                        </form>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Contact;
