import React from 'react'
import Header from '../views/Header'
import Footer from '../views/Footer'

const WhoWeAre = () => {
    return (
        <div>
            <Header />
            <div>
                <img src={require("../assets/image/who_we_are1.jpg")} alt="" />
                <div className='xl:px-10 2xl:px-40 lg:px-4 md:px-3 px-3 py-6 container w-auto md:w-full mx-auto'>
                    <div className='rounded-xl p-16 bg-[#E6E6E6] '>
                        <h2 className='md:text-4xl text-xl font-bold'>Editorial Policy</h2>
                        <p className='py-3 text-lg'>Editorial Policy of Revenue News is the ideals of The Indian Constitution.</p>
                        <p className='text-lg'>Revenue News is an Independent Media, engaged in fact- based, non-sensational journalism. A strong and independent Judiciary is the foundation of a fair, meaningful, and informed Democracy and Independent Judiciary in turn creates vibrant a democracy.</p>
                        <p className='py-3 text-lg'>Revenue News is supported by its subscribers in running its operation.</p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default WhoWeAre