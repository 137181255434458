import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';  // Import useNavigate and useSearchParams
import Header from './Header';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import { RxAvatar } from 'react-icons/rx';
import { MdRequestPage, MdSettings } from 'react-icons/md';
import { FaPencil } from 'react-icons/fa6';
import { collection, deleteDoc, doc, getDoc, updateDoc } from 'firebase/firestore';  // Import updateDoc
import { auth, db } from '../subscribe/firebase';
import { FaLock } from 'react-icons/fa';
import { toast } from 'react-toastify';

const Profile = () => {
    const [activeSection, setActiveSection] = useState('profile');
    const [userDetails, setUserDetails] = useState(null);
    const [fullname, setFullname] = useState('');
    const [email, setEmail] = useState('');
    // const [password, setPassword] = useState('');
    const [phonenumber, setPhonenumber] = useState('');
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const userId = searchParams.get('id');  // Get UID from URL

    // Fetch user data using UID from URL
    const fetchUserData = async (userId) => {
        try {
            const docRef = doc(db, "Users", userId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                const data = docSnap.data();
                setUserDetails(data);
                setFullname(data.fullName || '');
                setEmail(data.email || '');
                // setPassword(data.password || '');
                setPhonenumber(data.phonenumber || '');
            } else {
                console.log("No such user!");
            }
        } catch (error) {
            toast.error(`Failed to fetch user data: ${error.message}`);
        }
    };

    useEffect(() => {
        if (userId) {
            fetchUserData(userId);
        }
    }, [userId]);

    const updateUserData = async (userId) => {
        try {
            await updateDoc(doc(db, 'Users', userId), {
                fullName: fullname,
                email: email,
                // password: password,
                phonenumber: phonenumber,
            });
            toast.success('User updated successfully!');
            navigate('/');  // Redirect to home page
        } catch (error) {
            toast.error(`Failed to update user: ${error.message}`);
        }
    };

    const deleteUser = async (userId) => {
        try {
            await deleteDoc(doc(db, 'Users', userId));
            toast.success('User deleted successfully!');
            navigate('/');  // Redirect to home page
        } catch (error) {
            toast.error(`Failed to delete user: ${error.message}`);
        }
    };

    return (
        <div>
            <Header />
            <div className='xl:px-10 2xl:px-40 lg:px-4 md:px-3 px-3 py-6 container md:w-full mx-auto bg-[#F6F6F6] w-[100%] md:flex gap-6'>
                <div className='bg-white rounded-md p-3 md:w-[30%] gap-3 h-60'>
                    <h2 className={activeSection === 'profile' ? 'active bg-red-100 p-3' : 'p-3'}>
                        <a onClick={() => setActiveSection('profile')}>
                            <i className='bx bxs-dashboard'></i>
                            <span className="flex gap-3"><RxAvatar size={25} /> Profile</span>
                        </a>
                    </h2>
                    <h2 className={activeSection === 'edit-profile' ? 'active bg-red-100 p-3' : 'p-3'}>
                        <a onClick={() => setActiveSection('edit-profile')}>
                            <i className='bx bxs-message-dots'></i>
                            <span className="flex gap-3"><FaPencil size={25} /> Edit profile</span>
                        </a>
                    </h2>
                    <h2 className={activeSection === 'change-password' ? 'active bg-red-100 p-3' : 'p-3'}>
                        <Link to={'/forgotpassword'}>
                            <i className='bx bxs-message-dots'></i>
                            <span className="flex gap-3"><MdSettings size={25} /> Change Password</span>
                        </Link>
                    </h2>
                    <h2 className={` ${activeSection === 'billing' ? "active bg-red-100 p-3" : 'p-3'}`}>
                        <a onClick={() => setActiveSection('billing')}>
                            <i className='bx bxs-group'></i>
                            <span className="flex gap-3"><MdRequestPage size={25} /> Billing</span>
                        </a>
                    </h2>
                </div>

                <div className='md:w-[70%] p-3 bg-white rounded-md'>
                    {activeSection === "profile" && (
                        <>
                            <div>
                                <h2 className='p-3 w-full border-b md:text-2xl text-xl font-bold'>Profile</h2>
                                <div>
                                    {userDetails ? (
                                        <div>
                                            <div className='flex my-6 gap-6'>
                                                <div className='w-[30%]'>
                                                    {userDetails.imageURL ? (
                                                        <img src={userDetails.imageURL} alt="" className='w-full rounded-full mb-2' />
                                                    ) : (
                                                        <div className='bg-[#F6F6F6] w-full'>
                                                            <RxAvatar className='w-full h-full text-gray-500' />
                                                        </div>
                                                    )}
                                                </div>
                                                <div>
                                                    <h2 className='md:text-3xl text-xl font-bold'>{userDetails.fullName}</h2>
                                                    <h2>{userDetails.email}</h2>
                                                </div>
                                            </div>
                                            <div className='flex gap-6'>
                                                <h2 className='hover:bg-[#DA0B24] bg-gray-500 flex rounded-md p-3 text-white font-bold gap-3'>Edit <FaPencil size={18} /></h2>
                                                <h2 className='hover:bg-[#DA0B24] bg-gray-500 flex rounded-md p-3 text-white font-bold gap-3'>Change Password <FaLock size={18} /></h2>
                                            </div>

                                            <div className='bg-[#F6F6F6] border rounded-md p-3 my-3'>
                                                {userDetails.subscription === "premium" ? (
                                                    <div>
                                                        <h2 className='font-bold text-xl'>Premium Subscriber.</h2>
                                                    </div>
                                                ) : (
                                                    <div className='md:flex gap-6'>
                                                        <h2 className='font-bold text-xl'>No Subscription Yet.</h2>
                                                        <Link to="/pricing">
                                                            <button className='hover:bg-red-100 hover:shadow-md border hover:border-red-100 border-red-600 text-red-600 p-2 rounded-md 2xl:text-base text-sm'>
                                                                Subscribe
                                                            </button>
                                                        </Link>
                                                    </div>
                                                )}
                                            </div>
                                            <div>
                                                <button onClick={() => deleteUser(userId)} className="btn btn-danger p-2 rounded-md bg-red-500 px-3">Delete</button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <img src={require('../assets/image/logo.png')} alt="" />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    )}

                    {activeSection === "edit-profile" && (
                        <>
                            <div className=' p-3 bg-white rounded-md'>
                                <h2 className='p-3 w-full border-b md:text-2xl text-xl font-bold'>Edit Profile</h2>
                                <div>
                                    {userDetails ? (
                                        <div>
                                            <div className='flex gap-3 w-full my-6'>
                                                <div className="mb-3 flex flex-col w-full">
                                                    <label className="mb-1 font-bold text-sm text-gray-600">Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control border p-3 shadow-inner rounded-md"
                                                        placeholder="Name"
                                                        value={fullname}
                                                        onChange={(e) => setFullname(e.target.value)}
                                                        required
                                                    />
                                                </div>

                                                <div className="mb-3 flex flex-col w-full">
                                                    <label className="mb-1 font-bold text-sm text-gray-600">Email address</label>
                                                    <input
                                                        type="email"
                                                        className="form-control border p-3 shadow-inner rounded-md"
                                                        placeholder="Enter email"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            {/* <div className="mb-3 flex flex-col">
                                                <label className="mb-1 font-bold text-sm text-gray-600">Password</label>
                                                <input
                                                    type="password"
                                                    className="form-control border p-3 shadow-inner rounded-md"
                                                    placeholder="Enter password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    required
                                                />
                                            </div> */}

                                            <div className="mb-6 flex flex-col">
                                                <label className="mb-1 font-bold text-sm text-gray-600">Mobile Number</label>
                                                <input
                                                    type="number"
                                                    className="form-control border p-3 shadow-inner rounded-md"
                                                    placeholder="Mobile Number"
                                                    value={phonenumber}
                                                    onChange={(e) => setPhonenumber(e.target.value)}
                                                />
                                            </div>

                                            <button onClick={() => updateUserData(userId)} className="btn btn-primary p-2 rounded-md bg-blue-500 px-3">Update</button>
                                        </div>
                                    ) : (
                                        <div>
                                            <img src={require('../assets/image/logo.png')} alt="" />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    )}

                    {activeSection === "billing" && (
                        <>
                            <div className=' p-3 bg-white rounded-md'>
                                <h2 className='p-3 w-full border-b md:text-2xl text-xl font-bold'>Edit Profile</h2>
                                {userDetails.subscription === "premium" ? (
                                    <div>
                                        <h2 className='font-bold text-xl my-6'>Admin make you Premium Subscriber.</h2>
                                    </div>
                                ) : (
                                    <div className='md:flex gap-6 my-6'>
                                        <h2 className='font-bold text-xl'>You are not subscribe any plan.</h2>
                                        <Link to="/pricing">
                                            <button className='hover:bg-red-100 hover:shadow-md border hover:border-red-100 border-red-600 text-red-600 p-2 rounded-md 2xl:text-base text-sm'>
                                                Subscribe
                                            </button>
                                        </Link>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Profile;
