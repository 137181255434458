import React, { useState } from 'react'
import Header from '../views/Header'
import Footer from '../views/Footer'
import { toast, ToastContainer } from 'react-toastify';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db, storage } from '../subscribe/firebase';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';

const Request = () => {
    const [userForm, setUserForm] = useState({ fullName: '', email: '', subscription: '', phoneNumber: '', image: null, imageUrl: '' });
    const [uploadProgress, setUploadProgress] = useState(0);

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setUserForm({ ...userForm, [name]: value });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setUserForm(prevState => ({
                ...prevState,
                image: file,
                imageUrl: URL.createObjectURL(file)
            }));
        }
    };

    const handleUpload = async () => {
        if (!userForm.imageUrl) {
            toast.error('Please fill in all required fields.');
            return;
        }
        try {
            if (userForm.image) {
                const storageRef = ref(storage, `images/${userForm.image.name}`);
                const uploadTask = uploadBytesResumable(storageRef, userForm.image);

                uploadTask.on(
                    'state_changed',
                    (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        setUploadProgress(progress);
                    },
                    (error) => {
                        toast.error(`Image upload failed: ${error.message}`);
                    },
                    async () => {
                        try {
                            const imageUrl = await getDownloadURL(uploadTask.snapshot.ref);
                            await addDoc(collection(db, 'request'), {
                                imageUrl,
                                fullName: userForm.fullName,
                                email: userForm.email,
                                phoneNumber: userForm.phoneNumber,
                                timestamp: serverTimestamp()
                            });
                            // resetNewsContent();
                            toast.success('News content uploaded successfully!');
                            // fetchNews();
                        } catch (error) {
                            toast.error(`Failed to get download URL: ${error.message}`);
                        }
                    }
                );
            } else {
                await addDoc(collection(db, 'request'), {
                    imageUrl: '',
                    fullName: '',
                    email: '',
                    phoneNumber: '',
                    timestamp: serverTimestamp()
                });
                // resetNewsContent();
                toast.success('News content uploaded successfully!');
                // fetchNews();
            }
        } catch (error) {
            toast.error(`Failed to upload news: ${error.message}`);
        }
    };

    return (
        <div>
            <ToastContainer />
            <Header />
            <div className='xl:px-10 2xl:px-40 lg:px-4 md:px-3 px-3 container mx-auto'>
                <div className='py-12'>
                    <h2 className='font-bold md:text-3xl text-xl'>First Make Payment Here And Send Your Request Via This Form After Uploading Receipt</h2>
                    <h2 className='font-bold'>Bank Details</h2>
                    <h2>Account No</h2>
                </div>

                <div className='my-12'>
                    <form>
                        <div className='grid grid-cols-3 gap-6'>

                            <div className="form-group">
                                <label>Name:</label>
                                <input
                                    type="text"
                                    name="fullName"
                                    value={userForm.fullName}
                                    onChange={handleFormChange}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label>Email:</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={userForm.email}
                                    onChange={handleFormChange}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label>Phone Number:</label>
                                <input
                                    type="phoneNumber"
                                    name="phoneNumber"
                                    value={userForm.phoneNumber}
                                    onChange={handleFormChange}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label>Image:</label>
                                <input
                                    type="file"
                                    name="image"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                    className="form-control"
                                />
                                {userForm.imageUrl && (
                                    <img
                                        src={userForm.imageUrl}
                                        alt="Uploaded"
                                        className="mt-2"
                                        style={{ maxWidth: '200px', height: 'auto' }}
                                    />
                                )}
                                <p>Progress: {uploadProgress}%</p>
                            </div>
                        </div>
                        <button type="button" onClick={handleUpload} className="upload-button w-full bg-footerColor text-white px-4 py-2 mt-4">
                            Upload
                        </button>
                        {/* {currentUserId && (
                            <button type="button" onClick={resetUserContent} className="bg-gray-500 text-white px-4 py-2 mt-4">
                                Cancel
                            </button>
                        )} */}
                    </form>
                </div>

            </div>
            <Footer />
        </div>
    )
}

export default Request