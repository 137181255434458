import { collection, getDocs } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { db } from '../subscribe/firebase';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const BreakingNews = ({ onClose }) => {
  const [newsList, setNewsList] = useState([]);
  const [showPopup, setShowPopup] = useState(false); // State to control popup visibility
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const newsCollection = collection(db, 'banner-news');
        const newsSnapshot = await getDocs(newsCollection);
        const newsData = newsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setNewsList(newsData);
        setShowPopup(true); // Show popup once news is fetched
      } catch (error) {
        toast.error(`Failed to fetch news: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    const hasShownPopup = localStorage.getItem('hasShownBreakingNewsPopup');
    if (!hasShownPopup) {
      fetchNews();
    }
  }, []);

  const handleOnClose = () => {
    localStorage.setItem('hasShownBreakingNewsPopup', 'true');
    setShowPopup(false);
    onClose();
  };

  // if (loading) return <div>Loading...</div>;

  if (!showPopup) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-8">
        <h2 className="text-xl font-bold mb-4">Breaking News!</h2>
        {newsList.slice(0, 1).map(news => (
          <Link to={`/news/${news.id}`} key={news.id} className='text-lg'>
            <h2 className='font-bold'>{news.heading}</h2>
            <img src={news.imageUrl} alt="News" className='my-3 md:h-96 h-40' />
          </Link>
        ))}
        <button
          className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 w-full rounded-md focus:outline-none"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default BreakingNews;
