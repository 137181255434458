import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Header from '../views/Header';
import Footer from '../views/Footer';
// import SubscriptionForm from '../server/SubscriptionForm';
import { auth } from './firebase';
import { Link } from 'react-router-dom';
import axios from 'axios';

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);
console.log(stripePromise);

const PremiumPage = () => {
    // const success1 = "https://buy.stripe.com/c/pay/cs_test_a16eOp8JkXRkj6qXqagWxkWeI9kDzKPBO4Jcrj9Ody4Kb4RXCCnPtCb2rx#fidkdWxOYHwnPyd1blpxYHZxWjA0VVRdbjM1NlUyQXVIX1JEMURmNU9odkBUMjQxcnBRcX9uZERdc18wU1N%2FMVxRQEs0ZGxga2F9M0dAX2xMNmtccEtISm9kPVJjfUt9V21fbVxyNmw1VU5iNTUyNkMyQDBqbScpJ2N3amhWYHdzYHcnP3F3cGApJ2lkfGpwcVF8dWAnPyd2bGtiaWBabHFgaCcpJ2BrZGdpYFVpZGZgbWppYWB3dic%2FcXdwYHgl"
    // const success2 = "https://buy.stripe.com/c/pay/cs_test_a1Tfb76brt1LIc4U3r2VtgoZS8GsDMNUIRg8UkNZFYIyGIS2J9rhpx6G2C#fidkdWxOYHwnPyd1blpxYHZxWjA0VVRdbjM1NlUyQXVIX1JEMURmNU9odkBUMjQxcnBRcX9uZERdc18wU1N%2FMVxRQEs0ZGxga2F9M0dAX2xMNmtccEtISm9kPVJjfUt9V21fbVxyNmw1VU5iNTUyNkMyQDBqbScpJ2N3amhWYHdzYHcnP3F3cGApJ2lkfGpwcVF8dWAnPyd2bGtiaWBabHFgaCcpJ2BrZGdpYFVpZGZgbWppYWB3dic%2FcXdwYHgl"
    // const plans = {
    //     sixMonths: 'prod_QI2HveFtKVoVv3', // Replace with your actual plan IDs
    //     oneYear: 'prod_QI2EygkYSmB4hK',
    //     twoYears: 'prod_QI24TSQT01EWw4',
    //     threeYears: 'prod_QI200jl95zZjqX',
    // };
    const [user, setUser] = useState();
    const [paymentStatus, setPaymentStatus] = useState(false);
    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setUser(user);
        });
    });
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setUser(user); // Set user state based on Firebase auth state
        });

        return () => unsubscribe(); // Cleanup on unmount
    }, []);

    const fetchPaymentStatus = async (userId) => {
        try {
            const response = await fetch(`/api/payment-status?userId=${userId}`);
            const data = await response.json();
            setPaymentStatus(data.isPaid);
        } catch (error) {
            console.error('Error fetching payment status:', error);
        }
    };



    const [amount, setAmount] = useState(100); // Example amount in INR
    const [name, setName] = useState('Your Name');
    const [email, setEmail] = useState('your-email@example.com');
    const [contact, setContact] = useState('9999999999');

    const loadRazorpayScript = () => {
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.async = true;
        document.body.appendChild(script);
    };

    const handlePayment = async () => {
        try {
            const orderData = await axios.post('http://localhost:5000/create-order', {
                amount,
                currency: 'INR',
                receipt: 'receipt#1'
            });

            const { id, amount: orderAmount, currency } = orderData.data;

            const options = {
                key: 'rzp_test_GcZZFDPP0jHtC4',
                amount: orderAmount,
                currency,
                name: 'Your Company Name',
                description: 'Test Transaction',
                image: 'https://example.com/your_logo',
                order_id: id,
                handler: function (response) {
                    console.log(response);
                    alert('Payment successful');
                },
                prefill: {
                    name,
                    email,
                    contact
                },
                notes: {
                    address: 'Your Address'
                },
                theme: {
                    color: '#F37254'
                }
            };

            const rzp = new window.Razorpay(options);
            rzp.open();
        } catch (error) {
            console.error('Payment error', error);
        }
    };





    return (
        <div>
            {/* <Header /> */}

            <div className='w-full h-24 py-4 flex container mx-auto justify-between 2xl:px-44 xl:px-12 lg:px-6 px-3 bg-loginbg -z-50'>
                <Link to="/">
                    <img src={require("../assets/image/logo.png")} alt="Logo" className='md:w-20 w-16 md:mb-3 mb-3' />
                </Link>
                <Link to="/login">
                    <button className='hover:bg-red-100 hover:shadow-md border border-red-600 text-red-600 p-2 md:h-12 xl:px-8 lg:px-4 md:px-2 lg:mx-1 xl:mx-3 rounded-md 2xl:text-base text-sm'>
                        Existing User
                    </button>
                </Link>
            </div>



            <div className='xl:px-10 2xl:px-40 lg:px-4 md:px-3 px-3 py-6 container w-auto mx-auto'>
                <div className="row subscription_row">
                    <div className="subscription_title">
                        <h2 className="subscription_heading font-bold">Unlimited access to all contents of Revenue Just Rs 111 / Month </h2>
                    </div>
                    <p className="subscription_subheading">Most reliable, quick and growing only with subscriber support .</p>
                </div>
                {/* <div>
                    <button onClick={handlePayment} onLoad={loadRazorpayScript}>
                        Pay Now
                    </button>
                </div> */}
                <div className='grid md:grid-cols-3 grid-cols-1 justify-between items-center gap-6 py-6 px-3'>
                    {/* Plan 1: For 6 Months */}
                    <div className={`p-4 lg:p-12 lg:px-16 border text-center gap-3 rounded-lg w-full `}>
                        <h2 className='font-bold md:text-2xl'>For 6 Months</h2>
                        <h2 className='text-xl my-2'>₹ <span className='text-red-600 line-through'>1249</span></h2>
                        <h2 className='text-3xl font-bold text-red-600'>₹1099 <span className='text-xl'>+GST</span></h2>
                        <h2 className='text-gray-500 text-xl my-2'>(183 INR per month)</h2>
                        <div className='flex justify-center items-center'>
                            <h3 className='text-white w-24 bg-red-600 my-2 p-2 rounded-md'>Save 12%</h3>
                        </div>
                        {user ? (
                            <Link to="/request">
                                <button className='border p-2 text-white subscription_buttonlogin font-bold'>
                                    Subscribe
                                </button>
                            </Link>
                        ) : (
                            <Link to="/login">
                                <button className='border p-2 text-white subscription_buttonlogin font-bold'>
                                    Subscribe
                                </button>
                            </Link>
                        )}

                        {/* <Elements stripe={stripePromise}>
                            <SubscriptionForm planId={plans.sixMonths} />
                        </Elements> */}
                    </div>

                    {/* Plan 2: For 1 Year */}
                    <div className={`p-4 md:p-6 lg:pb-16 lg:pt-6 lg:px-16 border text-center gap-3 rounded-lg shadow-lg flex flex-col justify-center items-center ${paymentStatus ? 'bg-green-500' : ''}`}>
                        <h2 className='font-bold md:text-2xl'>For 1 Year</h2>
                        <h2 className='text-xl my-2'>₹ <span className='text-red-600 line-through'>2499</span></h2>
                        <h2 className='text-3xl font-bold text-red-600'>₹1849 <span className='text-xl'>+GST</span></h2>
                        <h2 className='text-gray-500 text-xl my-2'>(INR 154 per month)</h2>
                        <div className='flex justify-center items-center'>
                            <h3 className='text-white w-24 bg-red-600 my-2 p-2 rounded-md'>Save 26%</h3>
                        </div>
                        {user ? (
                            // <>
                            //     {success2 ? (
                            //         <button className='border p-2 text-white subscription_buttonlogin font-bold'>
                            //             Subscribed
                            //         </button>
                            //     ) : (
                            <Link to="/request">
                                <button className='border p-2 text-white subscription_buttonlogin font-bold '>
                                    Subscribe
                                </button>
                            </Link>
                            //     )}
                            // </>
                        ) : (
                            <Link to="/login">
                                <button className='border p-2 text-white subscription_buttonlogin font-bold '>
                                    Subscribe
                                </button>
                            </Link>
                        )}
                        {/* <Elements stripe={stripePromise}>
                            <SubscriptionForm planId={plans.oneYear} />
                        </Elements> */}
                    </div>

                    {/* Plan 3: For 3 Years */}
                    <div className={`p-4 lg:p-12 lg:px-16 border text-center gap-3 my-6 rounded-lg w-full ${paymentStatus ? 'bg-green-500' : ''}`}>
                        <h2 className='font-bold md:text-2xl'>For 3 Years</h2>
                        <h2 className='text-xl my-2'>₹ <span className='text-red-600 line-through'>7499</span></h2>
                        <h2 className='text-3xl font-bold text-red-600'>₹3999 <span className='text-xl'>+GST</span></h2>
                        <h2 className='text-gray-500 text-xl my-2'>(INR 111 per month)</h2>
                        <div className='flex justify-center items-center'>
                            <h3 className='text-white w-24 bg-red-600 my-2 p-2 rounded-md'>Save 47%</h3>
                        </div>
                        {user ? (
                            <Link to="/request">
                                <button className='border p-2 text-white subscription_buttonlogin font-bold'>
                                    Subscribe
                                </button>
                            </Link>
                        ) : (
                            <Link to="/login">
                                <button className='border p-2 text-white subscription_buttonlogin font-bold '>
                                    Subscribe
                                </button>
                            </Link>
                        )}
                        {/* <Elements stripe={stripePromise}>
                            <SubscriptionForm planId={plans.threeYears} />
                        </Elements> */}
                    </div>

                    {/* Plan 4: For 2 Years */}
                    <div className={`p-4 lg:p-12 lg:px-16 border text-center gap-3 rounded-lg w-full ${paymentStatus ? 'bg-green-500' : ''}`}>
                        <h2 className='font-bold md:text-2xl'>For 2 Years</h2>
                        <h2 className='text-xl my-2'>₹ <span className='text-red-600 line-through'>3999</span></h2>
                        <h2 className='text-3xl font-bold text-red-600'>₹2899 <span className='text-xl'>+GST</span></h2>
                        <h2 className='text-gray-500 text-xl my-2'>(121 INR per month)</h2>
                        <div className='flex justify-center items-center'>
                            <h3 className='text-white w-24 bg-red-600 my-2 p-2 rounded-md'>Save 28%</h3>
                        </div>
                        {user ? (
                            <Link to="/request">
                                <button className='border p-2 text-white subscription_buttonlogin font-bold'>
                                    Subscribe
                                </button>
                            </Link>
                        ) : (
                            <Link to="/login">
                                <button className='border p-2 text-white subscription_buttonlogin font-bold '>
                                    Subscribe
                                </button>
                            </Link>
                        )}
                        {/* <Elements stripe={stripePromise}>
                            <SubscriptionForm planId={plans.twoYears} />
                        </Elements> */}
                    </div>
                </div>
            </div>
            <Footer />
        </div >
    );
};

export default PremiumPage;
