import { collection, getDocs } from 'firebase/firestore';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../subscribe/firebase';
import { IoSearch } from 'react-icons/io5';

const SearchBar = () => {
    const [searchInput, setSearchInput] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [newsList, setNewsList] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const newsCollection = collection(db, 'news');
                const newsSnapshot = await getDocs(newsCollection);
                const newsList = newsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                console.log('Fetched news:', newsList);
                setNewsList(newsList);
                setLoading(false);
            } catch (error) {
                console.error(`Failed to fetch news: ${error.message}`);
                setLoading(false);
            }
        };

        fetchNews();
    }, []);

    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchInput(value);
        console.log('Search input:', value);
        if (value) {
            filterSearchResults(value);
        } else {
            setSearchResults([]);
            setDropdownVisible(false);
        }
    };

    const filterSearchResults = (query) => {
        console.log('Filtering results for query:', query);
        const filteredResults = newsList.filter(news => {
            if (news.heading && typeof news.heading === 'string') {
                return news.heading.toLowerCase().includes(query.toLowerCase());
            }
            console.warn('Missing or invalid title field in document:', news);
            return false;
        });
        console.log('Filtered results:', filteredResults);
        setSearchResults(filteredResults);
        setDropdownVisible(true);
    };

    const handleResultClick = (result) => {
        navigate(`/news/${result.id}`);
        setSearchInput('');
        setSearchResults([]);
        setDropdownVisible(false);
    };

    return (
        <div className='search-container w-full'>
            {/* <input
                type='text'
                value={searchInput}
                onChange={handleInputChange}
                onFocus={() => setDropdownVisible(true)}
                onBlur={() => setTimeout(() => setDropdownVisible(false), 200)} // delay to allow click event
                placeholder='Search...'
                className='search-input'
                disabled={loading}
            /> */}
          <div className='flex gap-3 md:w-[70%]'>
          <input
                type="text"
                placeholder=""
                value={searchInput}
                onChange={handleInputChange}
                onFocus={() => setDropdownVisible(true)}
                onBlur={() => setTimeout(() => setDropdownVisible(false), 200)}
                className='border p-2 w-full  md:px-3 '
            />
            <button type="submit" className='bg-red-600 p-3 px-8 text-white mx-3 rounded-md'><IoSearch /></button>
          </div>
            {isDropdownVisible && searchResults.length > 0 && (
                <div className='search-dropdown'>
                    {searchResults.map((result) => (
                        <div
                            key={result.id}
                            onClick={() => handleResultClick(result)}
                            className='search-result-item'
                        >
                            {result.heading}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SearchBar;
