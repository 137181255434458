import React from 'react'
import { IoLogoFacebook } from "react-icons/io";
import { IoLogoInstagram, IoLogoLinkedin, IoLogoTwitter, IoLogoWhatsapp, IoLogoYoutube } from "react-icons/io5";
import { Link, useNavigate } from 'react-router-dom';
import useScrollToTop from './useScrollToTop';

const Footer = () => {
    useScrollToTop(); 
    return (
        <div className='bg-footerColor text-white '>
            <div className='border-b border-white container mx-auto'>
                <div className='xl:px-10 2xl:px-40 lg:px-20 md:px-10 px-3 py-8 w-full'>
                    <div className='md:flex justify-between border-b  py-3'>
                        <div>
                            <img src={require("../assets/image/logo.png")} alt="footer" className='w-24' />
                        </div>
                        <div className='w-[30%] flex justify-end'>
                            <img src={require("../assets/image/free/footer_playstore-removebg-preview.png")} alt="footer2" className='lg:h-16 lg:w-[50%] w-[100%] md:w-[50%]' />
                            {/* <img src={require("../assets/image/free/footer_appstore-removebg-preview.png")} alt="footer1" className='lg:h-16 lg:w-[50%] w-[100%] md:w-[50%]' /> */}
                        </div>
                    </div>

                    {/* <div className='flex justify-between border-b  py-3'> */}
                        {/* <div className='grid md:grid-cols-5 xl:grid-cols-6 lg:grid-cols-6 grid-cols-3 lg:gap-6 gap-3 '>
                            <h2>प्रमुख कहानियाँ</h2>
                            <h2>सुप्रीम कोर्ट</h2>
                            <h2>उच्च न्यायालय</h2>

                            <h2 className='xl:mx-8'>उपभोक्ता मामले</h2>
                            <h2 className='xl:mx-8'>राउंड अप</h2>
                            <h2 className='xl:mx-8'>अंतरराष्ट्रीय</h2>

                            <h2>कानून स्कूल</h2>
                            <h2>आईबीसी समाचार</h2>
                            <h2>मध्यस्थता</h2>

                            <h2 className='xl:mx-8'>नौकरी अपडेट</h2>
                            <h2 className='xl:mx-8'>पुस्तक समीक्षा</h2>
                            <h2 className='xl:mx-8'>इवेंट्स कॉर्नर</h2>

                            <h2>कर</h2>
                            <h2>डाइजेस्ट</h2>
                            <h2>कानून जानें</h2>

                            <h2 className='xl:mx-8'>पर्यावरण</h2>
                            <h2 className='xl:mx-8'>पॉडकास्ट</h2>
                            <h2 className='xl:mx-8'>वीडियो</h2>
                        </div>
                    </div>

                    <div className='flex justify-between border-b  py-3'>
                        <div className='grid md:grid-cols-2 xl:grid-cols-3 lg:grid-cols-3 grid-cols-2 gap-3 '>
                            <div>कानून स्कूल कॉर्नर</div>

                            <h2 className='xl:mx-8'>पेपर आमंत्रण</h2>
                            <h2 className='xl:mx-8'>प्रतियोगिताएँ</h2>
                            <h2>इंटर्नशिप</h2>

                            <h2 className='xl:mx-8'>कानून स्कूल लेख</h2>
                            <h2 className='xl:mx-8'>स्कॉलरशिप/फेलोशिप</h2>
                            <h2 className=''>डिप्लोमा/सर्टिफिकेट कोर्स</h2>

                            <h2 className='xl:mx-8'>स्कूल प्रवेश</h2>
                            <h2 className='xl:mx-8'>सेमिनार</h2>
                            <h2>एलएलएम/पीएचडी</h2>

                            <h2 className='xl:mx-8'>मूट कोर्ट</h2>
                            <h2 className='xl:mx-8'>प्लेसमेंट</h2>
                        </div>

                        <div className='grid md:grid-cols-2 xl:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-3 '>
                            <h2 className=''>कानून फर्म</h2>

                            <h2 className='xl:mx-8'>डील समाचार</h2>
                            <h2>इवेंट्स</h2>
                            <h2 className='xl:mx-8'>विदेशी कानून फर्म</h2>

                            <h2 className=''>इंटर्नशिप</h2>
                            <h2 className='xl:mx-8'>नौकरी अपडेट</h2>
                            <h2 className=''>कानून फर्म लेख</h2>
                        </div>
                    </div> */}

                    <div className=''>
                        <div className='md:flex xl:gap-20 gap-6 p-6'>
                            <Link to='/who-we-are'>
                                <h2 className='font-bold'>हम कौन हैं</h2>
                            </Link>
                            <Link to='/contact-us'>
                                <h2 className='font-bold'>संपर्क करें</h2>
                            </Link>
                            <Link to='/'>
                                <h2 className='font-bold flex'><img src={require("../assets/image/free/footerbottom.png")} alt="" className='w-8 h-8' />हमारे साथ विज्ञापन करें</h2>
                            </Link>
                            <Link to='/'>
                                <h2 className='font-bold'>करियर</h2>
                            </Link>
                            <Link to='/privacy-policy'>
                                <h2 className='font-bold'>गोपनीयता नीति</h2>
                            </Link>
                            <Link to='/term-and-conditions'>
                                <h2 className='font-bold'>नियम और शर्तें</h2>
                            </Link>
                        </div>
                        <div className='flex justify-center items-center xl:gap-6 gap-3 '>
                            <IoLogoFacebook size={25} />
                            <IoLogoTwitter size={25} />
                            <IoLogoWhatsapp size={25} />
                            <IoLogoYoutube size={25} />
                            <IoLogoLinkedin size={25} />
                            <IoLogoInstagram size={25} />
                        </div>
                    </div>
                </div>
            </div>

            <div className='flex flex-col justify-center items-center py-6 container mx-auto'>
                <h2>2024 © सभी अधिकार सुरक्षित @Revenue News</h2>
                <h2>होकालवायर द्वारा संचालित</h2>
            </div>
        </div>
    )
}

export default Footer
