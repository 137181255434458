import React, { useState } from 'react';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "./firebase";
import { ToastContainer, toast } from "react-toastify";
import SignInwithGoogle from "../subscribe/SignInwithGoogle";
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await signInWithEmailAndPassword(auth, email, password);
            console.log("User logged in Successfully");
            // window.location.href = "/";
            toast.success("User logged in Successfully", {
                position: "top-center",
            });
            navigate('/');
        } catch (error) {
            console.log(error.message);

            toast.error(error.message, {
                position: "bottom-center",
            });
        }
    };

    return (

        <div className="2xl:container loginpage_cntr1 flex flex-col justify-center items-center mx-auto">
            <div className="md:flex ">
                <div className="col-xs-12 col-sm-12 col-md-6 loginpage_col11 w-full xl:px-20 2xl:px-40 lg:px-4 md:px-3 px-3 pt-10">
                    <div className="container1 loginpage_subcntr1">
                        <div className="md:flex ">
                            {/* <div className="col-xs-4 col-sm-2 col-md-3 loginpage_subcol_back_icon">
                                <a href="/" className="loginpage_subcol_back_icon_link">
                                    <span className="loginpage_subcol_back_icon_content hide">
                                        <i className="fa fa-arflex-left"></i>Back
                                    </span>
                                </a>
                            </div> */}
                            <div className="col-xs-12 col-sm-9 col-md-8 loginpage_subcol_content pt-8 w-full">
                                <div className="md:flex justify-between md:mx-0 mx-6">
                                    <div className="col-xs-12 col-sm-2 col-md-2 loginpage_subcol_logo">
                                        <Link to="/">
                                            <img src={require("../assets/image/logo.png")} alt="Live Law" title="Live Law" className="loginpage_subcol_logo_img w-20" />
                                        </Link>
                                    </div>
                                    <div className="col-xs-12 col-sm-10 col-md-10 loginpage_subcol_progressbar w-[70%]">
                                        <ul>
                                            <li><i className="fa"></i><p>Register</p></li>
                                            <li><i className="fa"></i><p>Payment</p></li>
                                            <li><i className="fa"></i><p>Summary</p></li>
                                        </ul>
                                    </div>
                                </div>
                                {/* <div className="flex loginpage_subcol_subscription hide">
                                    <div className="col-xs-3 col-sm-2 col-md-3">
                                        <h4 className="loginpage_subcol_subscription_year">1 Year</h4>
                                        <h5 className="loginpage_subcol_subscription_title">SUBSCRIPTION</h5>
                                    </div>
                                    <div className="col-xs-6 col-sm-8 col-md-6">
                                        <h2 className="loginpage_subcol_subscription_price">₹2,449/-</h2>
                                    </div>
                                    <div className="col-xs-3 col-sm-2 col-md-3 loginpage_subcol_subscription_change_cols">
                                        <p className="loginpage_subcol_subscription_change">
                                            <a href="/pricing">Change</a>
                                        </p>
                                    </div>
                                </div> */}
                                <div className="">
                                    <div className='flex justify-between'>
                                        <h3 className="loginpage_subcol_login font-bold">Existing Subscriber - Log in</h3>
                                        <Link to="/admin-form">
                                            <button className='bg-gray-900 rounded-3xl px-3 p-1 text-sm text-white'>
                                                Admin
                                            </button>
                                        </Link>
                                    </div>
                                    <p className="loginpage_subcol_login_content">Enter your credentials to access your account</p>
                                </div>
                                <div className="login_continue_with w-full">
                                    <form onSubmit={handleSubmit}>
                                        <div className='gap-3 mt-3 mb-6'>
                                            {/* <a href="/" className="flex justify-center items-center w-full border rounded-md"> */}
                                            {/* <span className="m-3"> */}
                                            <SignInwithGoogle />
                                            {/* <img src={require("../assets/image/free/loginGoogle.jpg")} align="center" alt="" className="img-flui loginpage_btn_im" /> */}
                                            {/* </span> */}
                                            {/* Continue with Google */}
                                            {/* </a> */}
                                            {/* <a href="/h-social-login/apple.jsp?app=rdes&amp;partner=livelaw&amp;redirectUrl=/" className="loginpage_btn btn d-flex justify-content-center align-items-center">
                                                <span className="mr-3">
                                                    <img src={require("../assets/image/free/login1.jpg")} align="center" alt="" className="img-fluid loginpage_btn_img" />
                                                </span>
                                                Continue with Apple
                                            </a> */}
                                        </div>
                                        {/* <h3>Login</h3> */}

                                        <div className="mb-3 flex flex-col">
                                            <label className='mb-1'>Email address</label>
                                            <input
                                                type="email"
                                                className="form-control p-2 border rounded-md shadow-inner"
                                                placeholder="Enter email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </div>

                                        <div className="mb-3 flex flex-col">
                                            <label className='mb-1'>Password</label>
                                            <input
                                                type="password"
                                                className="form-control p-2 border rounded-md shadow-inner"
                                                placeholder="Enter password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                        </div>

                                        <div className="d-grid">
                                            <button type="submit" className="subscription_buttonlogin font-bold w-full">
                                                Submit
                                            </button>
                                        </div>
                                        {/* <p className="forgot-password text-right">
                                            New user <a href="/register">Register Here</a>
                                        </p> */}
                                        {/* <SignInwithGoogle /> */}
                                    </form>
                                </div>
                                <div className="flex mt-2">
                                    <h6 className="loginpage_subscription_login">Not Registered ?
                                        <Link to="/register" className="loginpage_text_login">Register now</Link>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" bg-loginbg w-full h-full large-screen-images pt-40">
                    <div className="flex px-16 pt-20 py-4">
                        <div className="col-xs-12 col-sm-12 col-md-12 loginpage_subcntr2_title font-bold text-lg">
                            <h3>India’s no.1 legal news portal</h3>
                        </div>
                    </div>
                    <div className='px-16 py-8 gap-6'>
                        <div className="flex loginpage_subcntr2_subcol_flex py-3">
                            <div className="col-xs-1 col-sm-1 col-md-1 loginpage_subcntr2_subcol">
                                <img src={require("../assets/image/free/login1.jpg")} className="loginpage_subcntr2_subcol_img w-6" />
                            </div>
                            <div className="col-xs-11 col-sm-11 col-md-11 loginpage_subcntr2_subcol2">
                                <p className="loginpage_subcntr2_subcol2_details ml-6">Ad free content</p>
                            </div>
                        </div>
                        <div className="flex loginpage_subcntr2_subcol_flex py-3">
                            <div className="col-xs-1 col-sm-1 col-md-1 loginpage_subcntr2_subcol">
                                <img src={require("../assets/image/free/login1.jpg")} className="loginpage_subcntr2_subcol_img w-6" alt="zip icon" />
                            </div>
                            <div className="col-xs-11 col-sm-11 col-md-11 loginpage_subcntr2_subcol2">
                                <p className="loginpage_subcntr2_subcol2_details ml-6">
                                    Unlimited access to our archives, orders and judgement copies, etc.
                                </p>
                            </div>
                        </div>

                        <div className="flex loginpage_subcntr2_subcol_flex py-3">
                            <div className="col-xs-1 col-sm-1 col-md-1 loginpage_subcntr2_subcol">
                                <img src={require("../assets/image/free/login2.jpg")} className="loginpage_subcntr2_subcol_img w-6" alt="downloads icon" />
                            </div>
                            <div className="col-xs-11 col-sm-11 col-md-11 loginpage_subcntr2_subcol2">
                                <p className="loginpage_subcntr2_subcol2_details ml-6">
                                    Free copies of judgments with download facility
                                </p>
                            </div>
                        </div>

                        <div className="flex loginpage_subcntr2_subcol_flex py-3">
                            <div className="col-xs-1 col-sm-1 col-md-1 loginpage_subcntr2_subcol">
                                <img src={require("../assets/image/free/login3.jpg")} className="loginpage_subcntr2_subcol_img w-6" alt="digest icon" />
                            </div>
                            <div className="col-xs-11 col-sm-11 col-md-11 loginpage_subcntr2_subcol2">
                                <p className="loginpage_subcntr2_subcol2_details ml-6">
                                    Access to weekly and monthly digests
                                </p>
                            </div>
                        </div>

                        <div className="flex loginpage_subcntr2_subcol_flex py-3">
                            <div className="col-xs-1 col-sm-1 col-md-1 loginpage_subcntr2_subcol">
                                <img src={require("../assets/image/free/login4.jpg")} className="loginpage_subcntr2_subcol_img w-6" alt="folder icon" />
                            </div>
                            <div className="col-xs-11 col-sm-11 col-md-11 loginpage_subcntr2_subcol2">
                                <p className="loginpage_subcntr2_subcol2_details ml-6">
                                    Special coverage on Tax, IBC, Arbitration
                                </p>
                            </div>
                        </div>

                        <div className="flex loginpage_subcntr2_subcol_flex py-3">
                            <div className="col-xs-1 col-sm-1 col-md-1 loginpage_subcntr2_subcol">
                                <img src={require("../assets/image/free/login5.jpg")} className="loginpage_subcntr2_subcol_img bellicon_img w-6" alt="bell icon" />
                            </div>
                            <div className="col-xs-11 col-sm-11 col-md-11 loginpage_subcntr2_subcol2">
                                <p className="loginpage_subcntr2_subcol2_details ml-6">
                                    Exclusive notifications on phone and via email. Weekly judgement text/ video roundups
                                </p>
                            </div>
                        </div>

                        <div className="flex loginpage_subcntr2_subcol_flex py-3">
                            <div className="col-xs-1 col-sm-1 col-md-1 loginpage_subcntr2_subcol">
                                <img src={require("../assets/image/free/login6.jpg")} className="loginpage_subcntr2_subcol_img w-6" alt="files icon" />
                            </div>
                            <div className="col-xs-11 col-sm-11 col-md-11 loginpage_subcntr2_subcol2">
                                <p className="loginpage_subcntr2_subcol2_details ml-6">
                                    In-depth articles on current legal and constitutional issues
                                </p>
                            </div>
                        </div>


                    </div>
                    {/* Add other flexs with similar structure */}
                </div>
            </div>
            <div className="">
                <ToastContainer />
            </div>
        </div>
    );
};

export default Login;
