import React, { useState, useEffect, useRef, useCallback } from 'react';
import { deleteDoc, doc, getDoc, getDocs, orderBy, query, setDoc } from 'firebase/firestore';
import { db, storage } from '../subscribe/firebase'; // Adjust the path as needed
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { RxAvatar } from 'react-icons/rx';
import Menu from './Menu';
import BannerNews from './BannerNews';
import 'react-datepicker/dist/react-datepicker.css';
import Banner from './Banner';
import Ads from './Ads';
import { FaUser } from 'react-icons/fa';
import JoditEditor from 'jodit-react';
import firebase from 'firebase/app'; // Adjust the import path based on how you import Firebase in your project
import 'firebase/database';
import PopularNews from './PopularNews';
import RequestDetails from './RequestDetails';

const Admin = () => {

	const editor = useRef(null);
	const editorHeading = useRef(null);
	const editorParagraph = useRef(null);
	const editorViewParagraph = useRef(null);

	const editorRefs = {
		heading: useRef(null),
		paragraph: useRef(null),
		view_paragraph: useRef(null)
	};

	const [content, setContent] = useState('');

	const [users, setUsers] = useState([]);
	const [news, setNews] = useState([]);
	const [newsList, setNewsList] = useState([]);
	const [loading, setLoading] = useState(true);
	const [activeSection, setActiveSection] = useState('dashboard');
	const [newsContent, setNewsContent] = useState({
		heading: '',
		paragraph: '',
		view_paragraph: '',
		image: null,
		imageUrl: '',
		type: '',
		position: '',
		pages: [],
		tags: [],
	});
	const [uploadProgress, setUploadProgress] = useState(0);
	const [user, setUser] = useState(null);
	const [isPremium, setIsPremium] = useState(false);
	const [selectedPage, setSelectedPage] = useState('');
	const [selectedTag, setSelectedTag] = useState('');
	const [newsData, setNewsData] = useState([]);
	const [newsByDay, setNewsByDay] = useState({});
	const [newsByMonth, setNewsByMonth] = useState({});
	const [currentNewsId, setCurrentNewsId] = useState(null);
	const [totalUsers, setTotalUsers] = useState(0);
	const [premiumUsers, setPremiumUsers] = useState(0);
	const [userForm, setUserForm] = useState({ fullName: '', email: '', subscription: '', phoneNumber: '' });
	const [isEditing, setIsEditing] = useState(false);
	const [currentUserId, setCurrentUserId] = useState(null);


	// const handleTagUpdate = (newTag) => {
	// 	setSelectedTag((prevTags) => (Array.isArray(prevTags) ? [...prevTags, newTag] : [newTag]));
	//   };


	const addUser = async () => {
		try {
			await setDoc(doc(db, 'Users', userForm.email), { ...userForm });
			toast.success('User added successfully!');
			setUserForm({ name: '', email: '', subscription: 'simple' });
			fetchUsers();
		} catch (error) {
			toast.error(`Failed to add user: ${error.message}`);
		}
	};

	// Update user function
	const updateUser = async () => {
		try {
			await setDoc(doc(db, 'Users', currentUserId), { ...userForm }, { merge: true });
			toast.success('User updated successfully!');
			setUserForm({ name: '', email: '', subscription: 'simple', phoneNumber: '' });
			setIsEditing(false);
			setCurrentUserId(null);
			fetchUsers();
		} catch (error) {
			toast.error(`Failed to update user: ${error.message}`);
		}
	};

	// Delete user function
	const deleteUser = async (id) => {
		try {
			await deleteDoc(doc(db, 'Users', id));
			toast.success('User deleted successfully!');
			fetchUsers();
		} catch (error) {
			toast.error(`Failed to delete user: ${error.message}`);
		}
	};

	// Handle form changes
	const handleFormChange = (e) => {
		const { name, value } = e.target;
		setUserForm({ ...userForm, [name]: value });
	};

	// Load user data for editing
	const handleEditUser = (user) => {
		setIsEditing(true);
		setCurrentUserId(user.id);
		setUserForm({ fullName: user.fullName, email: user.email, subscription: user.subscription, phoneNumber: user.phoneNumber });
	};

	// Fetch users on component mount
	useEffect(() => {
		fetchUsers();
	}, [activeSection]);


	const handleEditNews = async (newsId) => {
		setCurrentNewsId(newsId);
		try {
			const newsDoc = await getDoc(doc(db, 'news', newsId));
			if (newsDoc.exists()) {
				const newsData = newsDoc.data();
				setNewsContent({
					...newsData,
					image: null, // Clear the image file
					imageUrl: newsData.imageUrl || ''
				});
				toast.success('News loaded for editing!');
				window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the form

				setNewsContent(prevState => ({
					...prevState,
					tags: Array.isArray(newsData.tags) ? newsData.tags : [] // Ensure tags are set correctly
				}));

			} else {
				toast.error('News not found');
			}
		} catch (error) {
			toast.error(`Failed to fetch news for editing: ${error.message}`);
		}
	};


	const handleUpload = async () => {
		if (!newsContent.heading || !newsContent.paragraph || !newsContent.imageUrl) {
			toast.error('Please fill in all required fields.');
			return;
		}

		try {
			let imageUrl = newsContent.imageUrl;

			if (newsContent.image) {
				const storageRef = ref(storage, `images/${newsContent.image.name}`);
				const uploadTask = uploadBytesResumable(storageRef, newsContent.image);

				uploadTask.on(
					'state_changed',
					(snapshot) => {
						const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
						setUploadProgress(progress);
					},
					(error) => {
						toast.error(`Image upload failed: ${error.message}`);
					},
					async () => {
						imageUrl = await getDownloadURL(uploadTask.snapshot.ref);
						saveNewsData(imageUrl);
					}
				);
			} else {
				saveNewsData(imageUrl);
			}
		} catch (error) {
			toast.error(`Failed to upload news: ${error.message}`);
		}
	};

	const saveNewsData = async (imageUrl) => {
		try {
			const newsData = {
				heading: newsContent.heading,
				paragraph: newsContent.paragraph,
				view_paragraph: newsContent.view_paragraph,
				imageUrl,
				pages: newsContent.pages,
				type: newsContent.type,
				tags: newsContent.tags,
				position: newsContent.position || '',
				timestamp: serverTimestamp()
			};

			if (currentNewsId) {
				// Update existing news
				await setDoc(doc(db, 'news', currentNewsId), newsData, { merge: true });
				toast.success('News updated successfully!');
			} else {
				// Add new news
				await addDoc(collection(db, 'news'), newsData);
				toast.success('News added successfully!');
			}

			resetNewsContent();
			fetchNews();
			setCurrentNewsId(null); // Reset current news ID
		} catch (error) {
			toast.error(`Failed to save news data: ${error.message}`);
		}
	};

	const resetUserContent = () => {
		setUserForm({
			fullName: '',
			email: '',
			phoneNumber: '',
			subscription: null,
			// imageUrl: '',
			// pages: [],
			// position: '',
			// type: ''
		});
		// setUploadProgress(0);
	};

	const resetNewsContent = () => {
		setNewsContent({
			heading: '',
			paragraph: '',
			view_paragraph: '',
			image: null,
			imageUrl: '',
			pages: [],
			position: '',
			tags: [],
			type: ''
		});
		setUploadProgress(0);
	};
	const deleteNews = async (id) => {
		try {
			await deleteDoc(doc(db, 'news', id));
			toast.success('News deleted successfully!');
			fetchNews(); // Refresh news list after deletion
		} catch (error) {
			toast.error(`Failed to delete news: ${error.message}`);
		}
	};

	useEffect(() => {
		const fetchUsers = async () => {
			try {
				const userCollection = collection(db, "Users");
				const userSnapshot = await getDocs(userCollection);
				const userList = userSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

				setUsers(userList);

				const totalUsers = userList.length;
				const premiumUsers = userList.filter(user => user.subscription === 'premium').length;

				setTotalUsers(totalUsers);
				setPremiumUsers(premiumUsers);

				setLoading(false);
			} catch (error) {
				toast.error(`Failed to fetch user data: ${error.message}`);
				setLoading(false);
			}
		};


		fetchUsers();

	}, [activeSection]);

	const handleNewsChange = (e) => {
		const { name, value } = e.target;
		setNewsContent(prevState => ({
			...prevState,
			[name]: value
		}));
	};

	const debouncedSetNewsContent = useCallback(
		debounce((newContent) => {
			setNewsContent(newContent);
		}, 300),
		[]
	);

	const handleContentChange = (name, newContent) => {
		debouncedSetNewsContent(prevState => ({
			...prevState,
			[name]: newContent
		}));
	};

	const handleFocus = useCallback((editorName) => {
		editorRefs[editorName].current && editorRefs[editorName].current.focus();
	}, []);

	const handleImageChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			setNewsContent(prevState => ({
				...prevState,
				image: file,
				imageUrl: URL.createObjectURL(file)
			}));
		}
	};

	const handlePageChange = (e) => {
		const { value, checked } = e.target;
		setNewsContent(prevState => {
			const pages = checked
				? [...prevState.pages, value]
				: prevState.pages.filter(page => page !== value);
			return { ...prevState, pages };
		});
	};

	const handleTagChange = (e) => {
		const { value, checked } = e.target;
		const { newsId } = newsContent;

		setNewsContent(prevState => {
			const currentTags = Array.isArray(prevState.tags) ? prevState.tags : [];
			let updatedTags;

			if (checked) {
				// Add new tag if checked
				updatedTags = [...currentTags, value];
			} else {
				// Remove tag if unchecked
				updatedTags = currentTags.filter(tag => tag !== value);
			}

			// Update Firebase with new tags
			//   firebase.database().ref(`news/${news.uid}`).update({
			// 	...prevState,
			// 	tags: updatedTags
			//   }).then(() => {
			// 	console.log('Tags updated successfully');
			//   }).catch(error => {
			// 	console.error('Error updating tags:', error);
			//   });

			return { ...prevState, tags: updatedTags };
		});
	};



	const handleTagUpdate = (newTag) => {
		setNewsContent((prevState) => ({
			...prevState,
			tags: Array.isArray(prevState.tags) ? [...prevState.tags, newTag] : [newTag],
		}));
	};



	const fetchUsers = async () => {
		try {
			const userCollection = collection(db, "Users");
			const userSnapshot = await getDocs(userCollection);
			const userList = userSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

			setUsers(userList);

			const totalUsers = userList.length;
			const premiumUsers = userList.filter(user => user.subscription === 'premium').length;

			setTotalUsers(totalUsers);
			setPremiumUsers(premiumUsers);

			setLoading(false);
		} catch (error) {
			toast.error(`Failed to fetch user data: ${error.message}`);
			setLoading(false);
		}
	};

	const toggleSubscription = async (userId, currentStatus) => {
		try {
			const newStatus = currentStatus === 'premium' ? 'simple' : 'premium';
			await setDoc(doc(db, 'Users', userId), { subscription: newStatus }, { merge: true });
			toast.success(`User subscription updated to ${newStatus}!`);
			fetchUsers();
		} catch (error) {
			toast.error(`Failed to update subscription: ${error.message}`);
		}
	};

	useEffect(() => {
		if (activeSection === 'users') {
			fetchUsers();
		}
	}, [activeSection]);

	useEffect(() => {
		const fetchNews = async () => {
			try {
				const newsCollection = collection(db, 'news');
				const newsQuery = query(newsCollection, orderBy('timestamp', 'desc'));
				const newsSnapshot = await getDocs(newsQuery);
				const newsList = newsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

				// Set the news list
				setNewsList(newsList);

				// Organize news by date
				const newsByDay = {};
				const newsByMonth = {};

				newsList.forEach(news => {
					const date = news.timestamp.toDate();
					const day = date.toISOString().split('T')[0]; // Format: YYYY-MM-DD
					const month = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`; // Format: YYYY-MM

					if (!newsByDay[day]) newsByDay[day] = 0;
					newsByDay[day] += 1;

					if (!newsByMonth[month]) newsByMonth[month] = 0;
					newsByMonth[month] += 1;
				});

				// Set state
				setNewsByDay(newsByDay);
				setNewsByMonth(newsByMonth);

				setLoading(false);
			} catch (error) {
				toast.error(`Failed to fetch news: ${error.message}`);
				setLoading(false);
			}
		};

		fetchNews();
	}, []);

	const fetchNews = async () => {
		try {
			const newsCollection = collection(db, 'news');
			const newsQuery = query(newsCollection, orderBy('timestamp', 'desc'));
			const newsSnapshot = await getDocs(newsQuery);
			const newsList = newsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

			// Set the news list
			setNewsList(newsList);

			// Organize news by date
			const newsByDay = {};
			const newsByMonth = {};

			newsList.forEach(news => {
				const date = news.timestamp.toDate();
				const day = date.toISOString().split('T')[0]; // Format: YYYY-MM-DD
				const month = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`; // Format: YYYY-MM

				if (!newsByDay[day]) newsByDay[day] = 0;
				newsByDay[day] += 1;

				if (!newsByMonth[month]) newsByMonth[month] = 0;
				newsByMonth[month] += 1;
			});

			// Set state
			setNewsByDay(newsByDay);
			setNewsByMonth(newsByMonth);

			setLoading(false);
		} catch (error) {
			toast.error(`Failed to fetch news: ${error.message}`);
			setLoading(false);
		}
	};

	const renderNewsData = (data) => {
		return Object.entries(data).map(([key, value]) => (
			<div key={key}>
				<strong>{key}:</strong> {value}
			</div>
		));
	};

	if (loading) {
        return <div className='w-full h-full flex justify-center items-center'>
            <img src={require('../assets/image/logo.png')} alt="" className='w-[10%] flex justify-center mt-64 items-center' />
        </div>;
    }


	const handlePageSelect = (page) => {
		setSelectedPage(page);
	};

	const filteredNews = newsList.filter(news => {
		if (isPremium) {
			return selectedPage ? news.pages.includes(selectedPage) : true;
		} else {
			return news.type === 'simple' && (selectedPage ? news.pages.includes(selectedPage) : true);
		}
	});
	// const filteredNews = newsList.filter(news => {
	// 	if (isPremium) {
	// 		return selectedPage ? news.pages.includes(selectedPage) && news.tags.some(tag => newsContent.tags.includes(tag)) : news.tags.some(tag => newsContent.tags.includes(tag));
	// 	} else {
	// 		return news.type === 'simple' && (selectedPage ? news.pages.includes(selectedPage) && news.tags.some(tag => newsContent.tags.includes(tag)) : news.tags.some(tag => newsContent.tags.includes(tag)));
	// 	}
	// });

	// const filteredTags = newsList.filter(news => {
	// 	if (isPremium) {
	// 		return selectedTag ? news.tags.includes(selectedTag) : true;
	// 	} else {
	// 		return news.type === 'simple' && (selectedTag ? news.tags.includes(selectedTag) : true);
	// 	}
	// })

	const renderContent = (content) => {
		return <div dangerouslySetInnerHTML={{ __html: content }} />;
	};
	return (
		<>

			<ToastContainer />
			<div className='flex overflow-hidden '>
				<div id="sidebar" className='border-r shadow-md'>
					<a href="#" className="brand p-3 gap-3">
						<a href='/' className='bx bxs-smile'><img src={require("../assets/image/logo.png")} alt="" className='w-20' /></a>
						<span className="text">AdminHub</span>
					</a>
					<ul className="side-menu top cursor-pointer">
						<li className={activeSection === 'dashboard' ? 'active bg-footerColor text-white' : ''}>
							<a onClick={() => setActiveSection('dashboard')}>
								<i className='bx bxs-dashboard'></i>
								<span className="text">Dashboard</span>
							</a>
						</li>
						<li className={activeSection === 'banner-news' ? 'active bg-footerColor text-white' : ''}>
							<a onClick={() => setActiveSection('banner-news')}>
								<i className='bx bxs-message-dots'></i>
								<span className="text">Notification Banner</span>
							</a>
						</li>
						<li className={activeSection === 'news' ? 'active bg-footerColor text-white' : ''}>
							<a onClick={() => setActiveSection('news')}>
								<i className='bx bxs-message-dots'></i>
								<span className="text">News</span>
							</a>
						</li>
						<li className={` ${activeSection === 'banner' ? "active bg-footerColor text-white" : ''}`}>
							<a onClick={() => setActiveSection('banner')}>
								<i className='bx bxs-group'></i>
								<span className="text">Banner</span>
							</a>
						</li>
						<li className={` ${activeSection === 'popular-news' ? "active bg-footerColor text-white" : ''}`}>
							<a onClick={() => setActiveSection('popular-news')}>
								<i className='bx bxs-group'></i>
								<span className="text">Popular News</span>
							</a>
						</li>
						<li className={` ${activeSection === 'ads' ? "active bg-footerColor text-white" : ''}`}>
							<a onClick={() => setActiveSection('ads')}>
								<i className='bx bxs-group'></i>
								<span className="text">Ads</span>
							</a>
						</li>
						<li className={` ${activeSection === 'users' ? "active bg-footerColor text-white" : ''}`}>
							<a onClick={() => setActiveSection('users')}>
								<i className='bx bxs-group'></i>
								<span className="text">Users</span>
							</a>
						</li>
					</ul>
					<ul className="side-menu">
						<li className={` ${activeSection === 'request' ? "active bg-footerColor text-white" : 'text-green-600'}`}>
							<a onClick={() => setActiveSection('request')}>
								<i className='bx bxs-group'></i>
								<span className="text">Users Request</span>
							</a>
						</li>
						<li>
							<a className="logout">
								<i className='bx bxs-log-out-circle'></i>
								<span className="text">Logout</span>
							</a>
						</li>
					</ul>
				</div>

				<div id="content">
					<nav>
						<i className=''></i>
						<a href="" className="nav-link font-bold">Categories</a>
						<form action="">
							<Menu onSelectPage={handlePageSelect} />
						</form>
						<input type="checkbox" id="switch-mode" hidden />
						<label htmlFor="switch-mode" className="switch-mode"></label>
						<a href="#" className="profile">
							<RxAvatar size={25} />
						</a>
					</nav>

					<main>
						<div>
							{selectedPage ? (
								<div>
									<h2 className='font-bold text-3xl'>News for {selectedPage}</h2>
									{loading ? (
										<p>Loading...</p>
									) : filteredNews.length > 0 ? (
										<ul>
											{filteredNews.map(news => (
												<li key={news.id} className='py-3 gap-6 md:flex justify-between border-b'>
													<h2>{news.heading}</h2>
													<h2>{news.view_paragraph}</h2>
													<h2>{news.paragraph}</h2>
													{news.imageUrl && <img src={news.imageUrl} alt="News" className="img-fluid " style={{ maxWidth: '100px', height: 'auto' }} />}
												</li>
											))}
										</ul>
									) : (
										<p>No news available for this page.</p>
									)}
								</div>
							) : (
								<>
									{activeSection === 'dashboard' && (
										<>
											<div>
												<div className="md:flex h-32 gap-6">
													<div className="widget p-3">
														<div className="left px-1">
															<span className="title">USERS</span>
															<span className="counter">
																{totalUsers}
															</span>
															{/* <span className="link">See all Users</span> */}
														</div>
														<div className="right">
															<div className="percentage positive">
																{/* <KeyboardArrowUpIcon /> */}
																{/* {diff} % */}
															</div>
															<FaUser size={25} className='text-green-700' />
														</div>
													</div>
													<div className="widget p-3">
														<div className="left px-1">
															<span className="title">PREMIUM USERS</span>
															<span className="counter">
																{premiumUsers}
															</span>
															{/* <span className="link">View all orders</span> */}
														</div>
														<div className="right">
															<div className="percentage positive">
																{/* <KeyboardArrowUpIcon /> */}
																{/* {diff} % */}
															</div>
															<div className='w-10'>
																<img src={require("../assets/image/premium.png")} alt="" />
															</div>
														</div>
													</div>
													<div className="widget p-3">
														<div className="left px-1">
															<span className="title">EARNINGS</span>
															<span className="counter">
																$4.6k
															</span>
															<span className="link">View net earnings</span>
														</div>
														<div className="right">
															<div className="percentage positive">
																{/* <KeyboardArrowUpIcon /> */}
																{/* {diff} % */}
															</div>
															earnings
														</div>
													</div>
													<div className="widget p-3">
														<div className="left px-1">
															<span className="title">MY BALANCE</span>
															<span className="counter">
																$7.8k
															</span>
															<span className="link">See details</span>
														</div>
														<div className="right">
															<div className="percentage positive">
																{/* <KeyboardArrowUpIcon /> */}
																{/* {diff} % */}
															</div>
															my balance
														</div>
													</div>
												</div>
											</div>
											{/* <Widget /> */}
											{/* <div className='md:flex gap-6'>
												<Featured />
												<Chart />
											</div> */}
											<div className="head-title">

											</div>
											<ul className="box-info">

											</ul>
											<div className="dashboard">
												<div className="chart-container">
													<div className='mb-6'>
														<h2 className='font-bold text-3xl text-footerColor'>News by Day</h2>
														{renderNewsData(newsByDay)}
													</div>
													<div>
														<h2 className='font-bold text-3xl text-footerColor'>News by Month</h2>
														{renderNewsData(newsByMonth)}
													</div>
												</div>
											</div>
										</>
									)}


									{activeSection === 'news' && (
										<div className="news-container">
											<h1 className='bg-footerColor p-3 text-white font-bold my-3'>News Section</h1>
											<form className="news-form">
												<div className="form-group">
													<label>Heading:</label>
													{/* <JoditEditor
														name="heading"
														ref={editorHeading}
														value={newsContent.heading}
														onChange={(newContent) => handleContentChange('heading', newContent)}
													// key={newsContent.heading}
													// onBlur={() => handleFocus('heading')}
													/> */}
													<input
														type="text"
														name="heading"
														value={newsContent.heading}
														onChange={handleNewsChange}
														className="form-control"
													/>
												</div>
												<div className="form-group">
													<label>Paragraph:</label>
													{/* <JoditEditor
														name="paragraph"
														ref={editorParagraph}
														value={newsContent.paragraph}
														onChange={(newContent) => handleContentChange('paragraph', newContent)}
													// onBlur={() => handleFocus('paragraph')}
													// key={newsContent.paragraph}
													/> */}
													<textarea
														name="paragraph"
														value={newsContent.paragraph}
														onChange={handleNewsChange}
														className="form-control"
														rows={10}
													></textarea>
												</div>
												<div className="form-group">
													{/* <label>View Paragraph:</label>
													<JoditEditor
														name="view_paragraph"
														ref={editorViewParagraph}
														value={newsContent.view_paragraph}
														onChange={(newContent) => handleContentChange('view_paragraph', newContent)}
													// onBlur={() => handleFocus('view_paragraph')}
													// key={newsContent.view_paragraph}
													/> */}
													<label>View Paragraph:</label>
													<textarea
														name="view_paragraph"
														value={newsContent.view_paragraph}
														onChange={handleNewsChange}
														className="form-control"
													></textarea>
												</div>
												<div className="form-group">
													<label>Image:</label>
													<input
														type="file"
														name="image"
														accept="image/*"
														onChange={handleImageChange}
														className="form-control"
													/>
													{newsContent.imageUrl && (
														<img
															src={newsContent.imageUrl}
															alt="Uploaded"
															className="mt-2"
															style={{ maxWidth: '200px', height: 'auto' }}
														/>
													)}
													<p>Progress: {uploadProgress}%</p>
												</div>
												<div className='flex items-center gap-3'>
													<label>News Type:</label>
													<select
														value={newsContent.type}
														onChange={(e) => setNewsContent({ ...newsContent, type: e.target.value })}
													>
														<option value=""></option>
														<option value="simple">Simple</option>
														<option value="premium">Premium</option>
													</select>
												</div>
												<div className="form-group flex gap-6">
													<div>

														<label>Pages:</label>
														<div className='md:flex w-full'>
															<div>
																<label>
																	<input type="checkbox" value="home" checked={newsContent.pages.includes('home')} onChange={handlePageChange} />
																	Custom
																</label>
																<label>
																	<input type="checkbox" value="gst" checked={newsContent.pages.includes('gst')} onChange={handlePageChange} />
																	GST
																</label>
																<label>
																	<input type="checkbox" value="enforcement-directorate" checked={newsContent.pages.includes('enforcement-directorate')} onChange={handlePageChange} />
																	Enforcement Directorate (ED)
																</label>
																<label>
																	<input type="checkbox" value="dri" checked={newsContent.pages.includes('dri')} onChange={handlePageChange} />
																	DRI
																</label>
																<label>
																	<input type="checkbox" value="dggi" checked={newsContent.pages.includes('dggi')} onChange={handlePageChange} />
																	DGGI
																</label>
																<label>
																	<input type="checkbox" value="income-tax-department" checked={newsContent.pages.includes('income-tax-department')} onChange={handlePageChange} />
																	Income Tax Department
																</label>
																<label>
																	<input type="checkbox" value="cbi" checked={newsContent.pages.includes('cbi')} onChange={handlePageChange} />
																	CBI
																</label>
																<label>
																	<input type="checkbox" value="narcotics-control-bureau" checked={newsContent.pages.includes('narcotics-control-bureau')} onChange={handlePageChange} />
																	Narcotics Control Bureau
																</label>
																<label>
																	<input type="checkbox" value="gold-smuggling" checked={newsContent.pages.includes('gold-smuggling')} onChange={handlePageChange} />
																	Gold Smuggling
																</label>
																<label>
																	<input type="checkbox" value="transfer-posting" checked={newsContent.pages.includes('transfer-posting')} onChange={handlePageChange} />
																	Transfer-Posting
																</label>
																<label>
																	<input type="checkbox" value="politics" checked={newsContent.pages.includes('politics')} onChange={handlePageChange} />
																	Politics
																</label>
																<label>
																	<input type="checkbox" value="legal-advice" checked={newsContent.pages.includes('legal-advice')} onChange={handlePageChange} />
																	Legal Advice
																</label>
																<label>
																	<input type="checkbox" value="interviews" checked={newsContent.pages.includes('interviews')} onChange={handlePageChange} />
																	Interviews
																</label>
																<label>
																	<input type="checkbox" value="editorial" checked={newsContent.pages.includes('editorial')} onChange={handlePageChange} />
																	Editorial
																</label>
																{/* Add more pages as needed */}
															</div>
															<div>
																<div className="form-group">
																	<label>Banner Position:</label>
																	<select
																		name="position"
																		value={newsContent.position || ''}
																		onChange={handleNewsChange}
																		className="form-control"
																	>
																		<option value="">None</option>
																		<option value="top">Top Banner</option>
																		<option value="bottom">Bottom Banner</option>
																	</select>
																</div>
															</div>
														</div>
													</div>
													<div>
														<label>Tags:</label>
														<div>
															<label>
																<input type="checkbox" value='custom' onChange={handleTagChange} />
																Custom
															</label>
															<label>
																<input type="checkbox" value="gst" onChange={handleTagChange} />
																GST
															</label>
															<label>
																<input type="checkbox" value="enforcement-directorate" onChange={handleTagChange} />
																Enforcement Directorate (ED)
															</label>
															<label>
																<input type="checkbox" value="dri" onChange={handleTagChange} />
																DRI
															</label>
															<label>
																<input type="checkbox" value="dggi" onChange={handleTagChange} />
																DGGI
															</label>
															<label>
																<input type="checkbox" value="income-tax-department" onChange={handleTagChange} />
																Income Tax Department
															</label>
															<label>
																<input type="checkbox" value="cbi" onChange={handleTagChange} />
																CBI
															</label>
															<label>
																<input type="checkbox" value="narcotics-control-bureau" onChange={handleTagChange} />
																Narcotics Control Bureau
															</label>
															<label>
																<input type="checkbox" value="gold-smuggling" onChange={handleTagChange} />
																Gold Smuggling
															</label>
															<label>
																<input type="checkbox" value="transfer-posting" onChange={handleTagChange} />
																Transfer-Posting
															</label>
															<label>
																<input type="checkbox" value="politics" onChange={handleTagChange} />
																Politics
															</label>
															<label>
																<input type="checkbox" value="legal-advice" onChange={handleTagChange} />
																Legal Advice
															</label>
															<label>
																<input type="checkbox" value="interviews" onChange={handleTagChange} />
																Interviews
															</label>
															<label>
																<input type="checkbox" value="editorial" onChange={handleTagChange} />
																Editorial
															</label>

														</div>
													</div>
												</div>
												<button type="button" onClick={handleUpload} className="upload-button bg-footerColor text-white px-4 py-2 mt-4">
													{currentNewsId ? 'Update' : 'Upload'}
												</button>
												{currentNewsId && (
													<button type="button" onClick={resetNewsContent} className="bg-gray-500 text-white px-4 py-2 mt-4">
														Cancel
													</button>
												)}
												{/* <button type="button" onClick={handleUpload} className="upload-button bg-footerColor text-white px-4 py-2 mt-4">
													Upload
												</button> */}
											</form>
											<div className='grid grid-cols-3 gap-3 py-12'>
												{loading ? (
													<div>Loading...</div>
												) : (
													newsList.map((news) => (
														<div key={news.id} className='border p-3 shadow-md rounded-md'>
															<h2 className='font-bold'>{renderContent(news.heading)}</h2>
															<img src={news.imageUrl} alt="News" className='w-full h-48 object-cover py-3' />
															<p className='text-sm'>{renderContent(news.paragraph)}</p>
															<p className='text-xs py-3'>{renderContent(news.view_paragraph)}</p>
															<p className='text-xs pb-3'>Type: {news.type}</p>
															<p className='text-xs pb-3'>Page: {news.pages}</p>
															<p className='text-xs pb-3'>Tags: {news.tags}</p>
															<p className='text-xs pb-3'>Position: {news.position}</p>
															<button onClick={() => deleteNews(news.id)} className='bg-red-500 text-white p-2 rounded-md'>Delete</button>
															<button onClick={() => handleEditNews(news.id)} className='bg-blue-500 text-white p-2 rounded-md ml-2'>Update</button>
														</div>
													))
												)}
											</div>
										</div>
									)}

									{activeSection === 'banner-news' && (
										<BannerNews />
									)}

									{activeSection === 'popular-news' && (
										<PopularNews />
									)}
									{activeSection === 'users' && (
										<div className="admin-container rounded-md">
											<div className="user-form">
												<h2 className='bg-footerColor p-3 text-white font-bold my-3 rounded-md'>{isEditing ? 'Edit User' : 'Add User'}</h2>
												<form>
													<div className='grid grid-cols-3 gap-6'>

														<div className="form-group">
															<label>Name:</label>
															<input
																type="text"
																name="fullName"
																value={userForm.fullName}
																onChange={handleFormChange}
																className="form-control"
															/>
														</div>
														<div className="form-group">
															<label>Email:</label>
															<input
																type="email"
																name="email"
																value={userForm.email}
																onChange={handleFormChange}
																className="form-control"
															/>
														</div>
														<div className="form-group">
															<label>Phone Number:</label>
															<input
																type="phoneNumber"
																name="phoneNumber"
																value={userForm.phoneNumber}
																onChange={handleFormChange}
																className="form-control"
															/>
														</div>
														<div className="form-group">
															<label>Subscription:</label>
															<select
																name="subscription"
																value={userForm.subscription}
																onChange={handleFormChange}
																className="form-control"
															>
																<option value="">None</option>
																<option value="simple">Simple</option>
																<option value="premium">Premium</option>
															</select>
														</div>
													</div>
													<button type="button" onClick={isEditing ? updateUser : addUser} className="w-full btn btn-primary bg-green-600 p-3 text-white font-bold my-3 rounded-md">
														{isEditing ? 'Update User' : 'Add User'}
													</button>
													{currentUserId && (
														<button type="button" onClick={resetUserContent} className="bg-gray-500 text-white px-4 py-2 mt-4">
															Cancel
														</button>
													)}
												</form>
											</div>
											<h1 className='bg-footerColor p-3 text-white font-bold my-3 rounded-md'>User Details</h1>
											{loading ? (
												<p>Loading...</p>
											) : (
												<table className="user-details-table rounded-md ">
													<thead className='text-red-600'>
														<tr>
															<th>Full Name</th>
															<th>Email</th>
															<th>Phone Number</th>
															<th>Subscription Status</th>
															<th>Action</th>
															<th>Edit</th>
														</tr>
													</thead>
													<tbody>
														{users.map(user => (
															<tr key={user.id}>
																<td>{user.fullName}</td>
																<td>{user.email}</td>
																<td>{user.phoneNumber}</td>
																<td>{user.subscription}</td>
																<td>
																	<button
																		onClick={() => toggleSubscription(user.id, user.subscription)}
																		className={`p-2 rounded-md ${user.subscription === 'premium' ? 'bg-red-500 text-white' : 'bg-green-500 text-white'
																			}`}
																	>
																		{user.subscription === 'premium' ? 'Make Simple' : 'Make Premium'}
																	</button>
																</td>
																<td className='flex justify-between'>
																	<button onClick={() => handleEditUser(user)} className="btn btn-warning p-2 rounded-md bg-green-500 px-3">Update</button>
																	<button onClick={() => deleteUser(user.id)} className="btn btn-danger p-2 rounded-md bg-red-500 px-3">Delete</button>
																</td>
															</tr>
														))}
													</tbody>
												</table>
											)}
										</div>
									)}
									{/* <div>
									<TableList />
								</div> */}
									{/* </div>
									)} */}

									{activeSection === 'banner' && (
										<Banner />
									)}

									{activeSection === 'request' && (
										<RequestDetails />
									)}

									{activeSection === 'ads' && (
										<Ads />
									)}
								</>
							)}
						</div>
					</main>
				</div >
			</div >
		</>
	);
}

function debounce(func, delay) {
	let timer;
	return function (...args) {
		clearTimeout(timer);
		timer = setTimeout(() => {
			func.apply(this, args);
		}, delay);
	};
}

export default Admin;
