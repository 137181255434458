import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth, db } from "./firebase";
import { toast } from "react-toastify";
import { setDoc, doc } from "firebase/firestore";
import { useNavigate } from "react-router";

function SignInwithGoogle() {
    const navigate = useNavigate(); // Get the navigate function from react-router-dom

    async function googleLogin() {
        const provider = new GoogleAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            console.log(result);
            const user = result.user;
            if (user) {
                await setDoc(doc(db, "Users", user.uid), {
                    email: user.email,
                    fullName: user.displayName,
                    photo: user.photoURL,
                });
                toast.success("User logged in Successfully", {
                    position: "top-center",
                });
                navigate('/'); // Navigate to the homepage without refreshing
            }
        } catch (error) {
            console.error("Error during sign in with Google", error);

            // Log detailed error message
            console.log("Error code:", error.code);
            console.log("Error message:", error.message);

            // Display user-friendly error message
            let errorMessage = "Login failed. Please try again.";
            if (error.code === 'auth/network-request-failed') {
                errorMessage = "Network error. Please check your connection and try again.";
            } else if (error.code === 'auth/popup-closed-by-user') {
                errorMessage = "Login cancelled. Please try again.";
            }
            toast.error(errorMessage, {
                position: "top-center",
            });
        }
    } 

    return (
        <div>
            {/* <p className="continue-p">--Or continue with--</p> */}
            <div
                style={{ display: "flex", justifyContent: "center", cursor: "pointer" }}
                onClick={googleLogin}
            >
                <a className="flex justify-center items-center w-full border rounded-md">
                    <span className="m-3">
                        <img src={require("../assets/image/free/loginGoogle.jpg")} width={"100%"} />
                    </span>
                    Continue with Google
                </a>
            </div>
        </div>
    );
}
export default SignInwithGoogle;