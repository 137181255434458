import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import Footer from '../views/Footer'
import Header from '../views/Header'

const Policy = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const faqs = [
        {
            question: "Collection of Information",
            answer: `i) It is possible for you to visit the Site without giving any personal information. However, when you register on the Site, we collect your information such as your name, sex, age, address, contact, email account, etc. We also retrieve your information from your email account, social networking platform etc when you register through these accounts. We may also collect information, when you provide feedback and user materials or participate in surveys, etc. ii) We may use "cookies", or similar`,
        },
        {
            question: "Use of Information",
            answer: "Any of the information we collect from you may be used in one of the following ways: i. We may your information to personalize your experience and to help us to respond to your individual requirements in a better manner.. ii. We continually strive to improve our Site offerings based on the information and feedback we receive from you. We use your information for improving the Site. iii. Your information helps us to effectively respond to your service requests, and support needs, and connect with you as required or to process your transaction. iv. We will use email address to send you information and updates pertaining to the Site. If you decide to join our mailing list, you will receive emails that may include news, updates, related product or service information, etc. You may unsubscribe from receiving such mails. v. When you send us electronic mail, we will keep a record of this information so that we can respond to you. We only collect information from you when you visit on our site or fill out a form. Also, when filling out a form on our site, you may be asked to enter your: name, e-mail address or phone number. In case you have submitted your personal information and contact details, we reserve the rights to Call, SMS, Email or WhatsApp about our products and offers, even if your number has DND activated on it and you agree for the same.",
        },
        {
            question: "Information Sharing",
            answer: "Revenue News may shares your information with any third party in the following circumstances: a) When it is requested or required by law or by any court, tribunal or governmental agency. b) We may share such information with officers, employees, affiliates and service providers for the purpose of processing personal information on our behalf. We will take appropriate assurance from such parties to comply with this Privacy Policy and follow appropriate confidentiality and security measures. c) We may present information, usually in the form of aggregate statistics on traffic to various pages in the Site, to our advertisers to help them to understand our users.",
        },
        {
            question: "Links to Third Party Sites",
            answer: "The Site may include links to other websites or applications. Such third party websites are governed by their respective privacy policies, which are beyond our control. Once you leave our servers, use of any information you provide is governed by the privacy policy of the operator of the application, you are visiting. We do not provide any personally identifiable information to third party websites / advertisers / ad-servers without your consent. Our payment gateway may collect your billing information and baking/debit/credit card details if you chose to subscribe on our Site. The subscriptions may be on auto renewal mode unless cancelled. If at any point you do not wish to auto-renew your subscription, you may cancel your subscription before the end of the subscription term.",
        },
        {
            question: "Security",
            answer: "Revenue News respects your privacy and use reasonable efforts to protect the same. We have adequate safeguards and infrastructure to comply with the laws in India to protect your personal information. We will comply with Information Technology Act, 2000 and all other applicable data protection laws in India to protect your privacy",
        },
        {
            question: "Grievance/ Queries ",
            answer: "Any complaints or concerns with regards to the processing of information provided by you or breach of these terms shall be immediately informed to the below email mentioned below through email:",
        },
    ];

    const toggleAnswer = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };
    return (
        <div>
            <Header />
            <div className='xl:px-10 2xl:px-40 lg:px-4 md:px-3 px-3 py-6 container w-auto md:w-full mx-auto'>
                <div>
                    <h2 className='xl:text-4xl text-gray-800 md:text-3xl font-bold'>Privacy Policy</h2>
                    <p className='text-lg py-6'>This Privacy Policy explains our policy regarding collection, use, disclosure and transfer of your information by [Revenue News] and/or its subsidiary(ies) and/or affiliate(s) (collectively referred to as the "Revenue News"), which operates online facilities including, without limitation, www.livelaw.in, mobile applications, social media sites and any other digital services or facilities operated or used by Revenue News from time to time (collectively the "Sites").</p>
                    <p className='text-lg'>This Privacy Policy forms part and parcel of the Terms of Use for the Sites.</p>
                    <p className='text-lg py-6'>By accessing the Site, you consent to collection, storage, and use of the personal information you provide (including any changes thereto as provided by you) for any of the services that we offer.</p>
                    <p className='text-lg'>Revenue News may change the Privacy Policy from time to time and changes will be posted on this Site. By continuing to access and use this Site after any such changes are posted, you will be deemed to have accepted such changes.</p>
                </div>
                <div className="w-full my-6">
                    {/* <h2 className="text-2xl font-bold my-6">Frequently Asked Questions</h2> */}
                    {faqs.map((faq, index) => (
                        <div key={index} className=" border-gray-300 py-4">
                            <div
                                className="flex justify-between items-center cursor-pointer bg-[#E6E6E6] rounded-xl p-6 font-bold"
                                onClick={() => toggleAnswer(index)}
                            >
                                <h3 className="text-lg font-semibold">{faq.question}</h3>
                                <FontAwesomeIcon
                                    icon={activeIndex === index ? faMinus : faPlus}
                                    className="text-2xl"
                                />
                            </div>
                            {activeIndex === index && (
                                <div className={`overflow-hidden transition-height duration-[1000ms] border rounded-xl my-1 p-6 ease-in-out ${activeIndex === index ? 'max-h-screen' : 'h-0'}`}>
                                    <p className="mt-2 text-gray-600">{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Policy