import React, { useEffect, useState } from 'react'
import { deleteDoc, doc, getDocs } from 'firebase/firestore';
import { db, storage } from '../subscribe/firebase'; // Adjust the path as needed
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

const RequestDetails = () => {
    const [newsList, setNewsList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [newsContent, setNewsContent] = useState({
        image: null,
        imageUrl: '',
        heading: '',
    });
    const [uploadProgress, setUploadProgress] = useState(0);
    useEffect(() => {
        const fetchNews = async () => {
            try {
                const newsCollection = collection(db, 'request');
                const newsSnapshot = await getDocs(newsCollection);
                const newsList = newsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setNewsList(newsList);
                setLoading(false);
            } catch (error) {
                toast.error(`Failed to fetch news: ${error.message}`);
                setLoading(false);
            }
        };


        fetchNews();
    }, []);

    const deleteRequest = async (id) => {
        try {
            await deleteDoc(doc(db, 'request', id));
            toast.success('News deleted successfully!');
            fetchNews(); // Refresh news list after deletion
        } catch (error) {
            toast.error(`Failed to delete news: ${error.message}`);
        }
    };

    const fetchNews = async () => {
        try {
            const newsCollection = collection(db, 'request');
            const newsSnapshot = await getDocs(newsCollection);
            const newsList = newsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setNewsList(newsList);
            setLoading(false);
        } catch (error) {
            toast.error(`Failed to fetch news: ${error.message}`);
            setLoading(false);
        }
    };
    return (
        <div>
            <div className='grid grid-cols-3 gap-3 py-12'>

                {newsList.map((news) => (
                    <div key={news.id} className='border p-3 shadow-md rounded-md'>
                        <h2 className='font-bold'>{news.fullName}</h2>
                        <p className='text-sm'>{news.email}</p>
                        <p className='text-sm'>{news.phoneNumber}</p>
                        <img src={news.imageUrl} alt="News" className='w-full h-48 object-cover py-3' />
                        {/* <p className='text-xs py-3'>{news.view_paragraph}</p>
            <p className='text-xs pb-3'>Type: {news.type}</p> */}
                        <button onClick={() => deleteRequest(news.id)} className='bg-red-500 w-full text-white p-2 rounded-md'>Delete</button>
                    </div>
                ))}

            </div>
        </div>
    )
}

export default RequestDetails